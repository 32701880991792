<template>
  <div>
    <b-card>
      <h3 class="font-weight-bold py-3 mb-4">Relatório</h3>
      <b-row>
        <b-col cols="3" v-if="user.account_type == 0">
          <label class="form-label">Tipo de relatório</label>
          <multiselect
            v-model="reportType"
            :options="select_choices"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder
            noResult="Nenhuma opção encontrada, experimente digitar outras palavras"
            noOptions="Não há opções para mostrar"
            selectLabel
            selectedLabel="Selecionado"
            deselectLabel
            label="name"
            track-by="name"
          />
        </b-col>
        <b-col cols="3" v-if="user.account_type == 0 && reportType.value == 0">
          <label class="form-label">Usuário</label>
          <multiselect
            v-model="selected_user"
            :options="users"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder
            noResult="Nenhuma opção encontrada, experimente digitar outras palavras"
            noOptions="Não há opções para mostrar"
            selectLabel
            selectedLabel="Selecionado"
            deselectLabel
            label="name"
            track-by="name"
          />
        </b-col>
        <b-col cols="3">
          <div class="col-md mb-4 date_picker_custom" style="min-width: 16rem">
            <label class="form-label">Busca por data(s)</label>
            <b-input-group>
              <flat-pickr
                @on-change="filterpicker_model"
                @on-close="
                  (selectedDates, dateStr, instance) => {
                    if (selectedDates.length == 1) {
                      instance.setDate(
                        [selectedDates[0], selectedDates[0]],
                        true
                      );
                    }
                  }
                "
                ref="filterpicker"
                v-model="filterpicker_model_i"
                :config="filterpicker_config"
              />
              <b-input-group-append>
                <b-button
                  class="px-2"
                  variant="secondary"
                  @click="
                    () => {
                      filterpicker_model_i = '';
                      $set(filters, 'expected_finish_date_gte', undefined);
                      $set(filters, 'expected_finish_date_lte', undefined);
                    }
                  "
                >
                  <i class="fas fa-times" style="font-size: 12px"></i>
                </b-button>
                <b-dd size="sm" :right="true">
                  <b-dd-item-btn
                    @click="
                      () => {
                        let d1 = new Date(),
                          d2 = new Date();
                        $refs.filterpicker.fp.setDate([d1, d2], true);
                      }
                    "
                    >Hoje</b-dd-item-btn
                  >
                  <b-dd-item-btn
                    @click="
                      () => {
                        let d1 = new Date(),
                          d2 = new Date();
                        d2.setDate(d2.getDate() - 7);
                        $refs.filterpicker.fp.setDate([d1, d2], true);
                      }
                    "
                    >Últimos 7 dias</b-dd-item-btn
                  >
                  <b-dd-item-btn
                    @click="
                      () => {
                        let d1 = new Date(),
                          d2 = new Date();
                        d2.setDate(d2.getDate() - 15);
                        $refs.filterpicker.fp.setDate([d1, d2], true);
                      }
                    "
                    >Últimos 15 dias</b-dd-item-btn
                  >
                  <b-dd-item-btn
                    @click="
                      () => {
                        let d1 = new Date(),
                          d2 = new Date();
                        d2.setMonth(d2.getMonth() - 1);
                        $refs.filterpicker.fp.setDate([d1, d2], true);
                      }
                    "
                    >Últimos 30 dias</b-dd-item-btn
                  >
                </b-dd>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <b-table
        :items="reportData"
        :fields="fields"
        :no-local-sorting="true"
        :outlined="true"
        :small="true"
        ref="table"
        class="card-table"
        v-if="reportType.value == 1"
      ></b-table>
      <div v-else>
        <h5 class="font-weight-bold py-3 mb-4">
          Relatório analitico de {{ user.name }}
        </h5>
        <b-row>
          <b-col cols="5">
            <LineChart
              :tasks="reportData"
              :dateStart="filters.created_at__gte"
              :dateFinish="filters.created_at__lte"
            />
          </b-col>
          <b-col cols="2"></b-col>
          <b-col cols="5">
            <PieChart
              :tasks="reportData"
              :dateStart="filters.created_at__gte"
              :dateFinish="filters.created_at__lte"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style
  src="@/vendor/libs/vue-multiselect/vue-multiselect.scss"
  lang="scss"
></style>

<style>
div.date_picker_custom > div.input-group > input.form-control {
  position: relative !important;
  width: calc(100% - 73px) !important;
  text-align: left !important;
  color: #35495e !important;
  min-height: 44px !important;
  display: inline !important;
  border-radius: 5px !important;
  border: 1px solid #e8e8e8 !important;
  background: #fff !important;
  font-size: 14px !important;
}
</style>
<script>
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import loading from "@/utils/loading";
import utils from "@/utils/utils";
import users_service from "@/services/auth/users";
import auth_service from "@/services/auth/auth";
import area_service from "@/services/general/areas";
import sectors_service from "@/services/general/sectors";
import tasks_service from "@/services/tasks/tasks";

function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export default {
  name: "PAudit",
  metaInfo: {
    title: "Relatórios",
  },
  components: {
    flatPickr: () => import("vue-flatpickr-component"),
    Multiselect: () => import("vue-multiselect"),
    LineChart: () => import("@/components/Report/LineReportChart"),
    PieChart: () => import("@/components/Report/PieReportChart"),
  },

  data: () => {
    let user = auth_service.getUserInformation();
    let select_choices = [
      { value: 1, name: "Sintético" },
      { value: 0, name: "Analítico" },
    ];
    return {
      loading,
      user,
      select_choices,
      reportType:
        user.account_type == 0 ? select_choices[1] : select_choices[1],
      filterpicker_model_i: "",
      filterpicker_config: {
        altInput: true,
        animate: true,
        dateFormat: "d/m/Y",
        altFormat: "d/m/Y",
        mode: "range",
        locale: Portuguese,
        disable: [
          function (date) {
            // return true to disable
            return date > new Date();
          },
        ],
      },
      fields: [
        {
          key: "name",
          sortable: true,
          tdClass: "align-middle  p-0 text-center position-relative",
          label: "Nome",
          width: "4rem",
        },
        {
          key: "area",
          sortable: true,
          tdClass: "align-middle  p-0 text-center position-relative",
          label: "Áreas",
          formatter: (obj) => (obj ? obj.map((o) => o.name).join(", ") : ""),
          width: "4rem",
        },
        {
          key: "sector",
          sortable: true,
          tdClass: "align-middle  p-0 text-center position-relative",
          label: "Area/Setores",
          formatter: (obj) => (obj ? obj.map((o) => o.name).join(", ") : ""),
          width: "4rem",
        },
        {
          key: "qtd_tasks",
          sortable: true,
          tdClass: "align-middle  p-0 text-center position-relative",
          label: "Qtd. Tarefas",
          width: "4rem",
        },
        {
          key: "qtd_on_time",
          sortable: true,
          tdClass: "align-middle  p-0 text-center position-relative",
          label: "Qtd. Entregues na data",
          width: "4rem",
        },
        {
          key: "qtd_expired",
          sortable: true,
          tdClass: "align-middle  p-0 text-center position-relative",
          label: "Qtd. Tarefas entregues atrasadas",
          width: "4rem",
        },
        {
          key: "qtd_open",
          sortable: true,
          tdClass: "align-middle  p-0 text-center position-relative",
          label: "Qtd. Tarefas em aberto",
          width: "4rem",
        },
        {
          key: "qtd_hours",
          sortable: true,
          tdClass: "align-middle  p-0 text-center position-relative",
          label: "Qtd. Horas trabalhadas",
          width: "4rem",
        },
      ],
      reportData: [],
      filters: {
        updated_at__gte: "",
        updated_at__lte: "",
      },
      users: [],
      selected_user: user,
    };
  },
  created() {
    function setToday(self) {
      if (
        self.$refs.filterpicker &&
        self.$refs.filterpicker.fp &&
        self.$refs.filterpicker.fp.setDate
      ) {
        let d1 = new Date(),
          d2 = new Date();

        d2.setDate(d2.getDate() - 7);
        self.$refs.filterpicker.fp.setDate([d1, d2], true);
      } else {
        setTimeout(() => {
          setToday(self);
        }, 10);
      }
    }

    setTimeout(() => {
      setToday(this);
    }, 5);
    this.refresh();
  },
  methods: {
    refresh() {
      if (this.reportType.value == 1) {
        let users_service_loading = loading.addLoader("users_service");
        users_service
          .find({ is_active: true }, undefined, undefined, true)
          .then((resp) => {
            resp.map((user) => {
              user.name = capitalize(user.name);
              return user;
            });
            resp.sort((a, b) => {
              return a.name > b.name ? 1 : -1;
            });
            this.$set(this, "reportData", resp);

            Promise.all(
              this.reportData.map((user, index) => {
                let user_service_loading = loading.addLoader("user_service");
                return users_service
                  .get(user.id)
                  .then((resp) => {
                    this.$set(
                      this.reportData,
                      index,
                      Object.assign(user, resp)
                    );
                  })
                  .finally(() => {
                    user_service_loading.done();
                  });
              })
            ).then(() => {
              let area_service_loading = loading.addLoader("area_service");
              let sector_service_loading = loading.addLoader("sector_service");
              let areas = this.reportData
                .map((user) => user.area)
                .reduce((a, b) => a.concat(b));

              let sectors = this.reportData
                .map((user) => user.sector)
                .reduce((a, b) => a.concat(b));

              Promise.all([
                area_service.prefetch(areas).finally(() => {
                  area_service_loading.done();
                }),
                sectors_service.prefetch(sectors).finally(() => {
                  sector_service_loading.done();
                }),
              ]).then(() => {
                this.reportData.forEach((user, index) => {
                  user.name = capitalize(user.name);
                  user.area.forEach((area, area_index) => {
                    let area_service_loading =
                      loading.addLoader("area_service");
                    area_service
                      .get(area)
                      .then((resp) => {
                        user.area[area_index] = resp;
                        this.$set(this.reportData, index, user);
                      })
                      .finally(() => {
                        area_service_loading.done();
                      });
                  });
                  user.sector.forEach((sector, sector_index) => {
                    let sector_service_loading =
                      loading.addLoader("sector_service");
                    sectors_service
                      .get(sector)
                      .then((resp) => {
                        user.sector[sector_index] = resp;
                        this.$set(this.reportData, index, user);
                      })
                      .finally(() => {
                        sector_service_loading.done();
                      });
                  });

                  let params = {};
                  for (const key in this.filters) {
                    if (this.filters.hasOwnProperty(key)) {
                      const element = this.filters[key];
                      if (element) {
                        params[key] = element;
                      }
                    }
                  }
                  let tasks_service_loading =
                    loading.addLoader("tasks_service");
                  tasks_service
                    .find(Object.assign({ responsible: user.id }, params))
                    .then((resp) => {
                      user.tasks = resp;
                      let done_tasks = resp.filter((t) => t.status == 2);
                      user.qtd_tasks = resp.length;
                      user.qtd_on_time = done_tasks.filter(
                        (t) =>
                          new Date(t.expected_finish_date) >
                          new Date(t.finish_date)
                      ).length;
                      user.qtd_expired = done_tasks.length - user.qtd_on_time;
                      user.qtd_open = user.qtd_tasks - done_tasks.length;
                      user.qtd_hours =
                        done_tasks.length == 0
                          ? 0
                          : done_tasks
                              .map((t) => t.spent_hours)
                              .reduce((a, b) => a + b);
                      this.$set(this.reportData, index, user);
                    })
                    .finally(() => {
                      tasks_service_loading.done();
                    });
                });
              });
            });
          })
          .catch((e) => {
            // console.log(e);
            // console.log("rejected");
          })
          .finally(() => {
            users_service_loading.done();
          });
        loading.isDone();
      } else {
        let users_service_loading = loading.addLoader("users_service");
        users_service
          .find({ is_active: true }, undefined, undefined, true)
          .then((resp) => {
            this.$set(this, "users", resp);
            let params = {};
            for (const key in this.filters) {
              if (this.filters.hasOwnProperty(key)) {
                const element = this.filters[key];
                if (element) {
                  params[key] = element;
                }
              }
            }
            let tasks_service_loading = loading.addLoader("tasks_service");
            tasks_service
              .find(
                Object.assign({ responsible: this.selected_user.id }, params),
                undefined,
                undefined,
                true
              )
              .then((resp) => {
                this.$set(this, "reportData", resp);
              })
              .catch((e) => {
                // console.log(e);
                // console.log("rejected");
              })
              .finally(() => {
                tasks_service_loading.done();
              });
          })
          .catch((e) => {
            // console.log(e);
            // console.log("rejected");
          })
          .finally(() => {
            users_service_loading.done();
          });
        loading.isDone();
      }
    },
    filterpicker_model(selectedDates) {
      if (selectedDates.length > 1) {
        let d1 = new Date(selectedDates[0]);
        d1.setDate(d1.getDate() - 1);
        let d2 = new Date(selectedDates[1]);
        d2.setDate(d2.getDate() + 1);

        d1 = utils.format_date(d1);
        d2 = utils.format_date(d2);
        if (this.reportType.value == 0) {
          this.$set(this.filters, "updated_at__gte", "");
          this.$set(this.filters, "updated_at__lte", "");
          this.$set(this.filters, "created_at__gte", d1);
          this.$set(this.filters, "created_at__lte", d2);
        } else {
          this.$set(this.filters, "created_at__gte", "");
          this.$set(this.filters, "created_at__lte", "");
          this.$set(this.filters, "updated_at__gte", d1);
          this.$set(this.filters, "updated_at__lte", d2);
        }
      }
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        if (!this.debounced_refresh) {
          this.debounced_refresh = _.debounce(this.refresh, 100);
        }
        this.debounced_refresh();
      },
    },
    reportType() {
      if (!this.debounced_refresh) {
        this.debounced_refresh = _.debounce(this.refresh, 100);
      }
      this.debounced_refresh();
    },
    selected_user() {
      if (!this.selected_user) {
        this.selected_user = this.user;
      }
      // console.log(this.selected_user);
      if (!this.debounced_refresh) {
        this.debounced_refresh = _.debounce(this.refresh, 100);
      }
      this.debounced_refresh();
    },
  },
};
</script>
