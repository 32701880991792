<template>
	<div class="authentication-wrapper authentication-3">
		<div class="authentication-inner">
			<!-- Side container -->
			<!-- Do not display the container on extra small, small and medium screens -->
			<div
				class="d-none d-lg-flex col-lg-8 align-items-center ui-bg-cover ui-bg-overlay-container p-5"
				:style="{
					'background-image':
						'url(' + require('@/assets/img/bg/bg.jpg') + ')',
				}"
			>
				<div class="ui-bg-overlay bg-dark opacity-50"></div>

				<!-- Text -->
				<div class="w-100 text-white px-5">
					<h1 class="display-2 font-weight-bolder mb-4">
						Gerenciador de tarefas
						<br />Maq-Larem
					</h1>
					<div class="text-large font-weight-light">
						Tenha um dia produtivo!
					</div>
				</div>
				<!-- /.Text -->
			</div>
			<!-- / Side container -->

			<!-- Form container -->
			<div class="d-flex col-lg-4 align-items-center bg-white p-5">
				<!-- Inner container -->
				<!-- Have to add `.d-flex` to control width via `.col-*` classes -->
				<div
					class="d-flex co\l-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto"
				>
					<div class="w-100">
						<!-- Logo -->
						<div
							class="d-flex justify-content-center align-items-center"
						>
							<div class="ui-w-60">
								<div
									class="w-100 position-relative"
									style="padding-bottom: 100%"
								>
									<img
										class="w-100 h-100 position-absolute"
										src="@/assets/img/logo.png"
										height="50px"
									/>
								</div>
							</div>
						</div>
						<!-- / Logo -->

						<h4
							class="text-center text-lighter font-weight-normal mt-5 mb-0"
						>
							Entre na sua conta
						</h4>

						<!-- Form -->
						<form class="my-5">
							<b-form-group label="Email">
								<b-input
									v-model="credentials.email"
									@keyup.enter="doAuth"
								/>
							</b-form-group>
							<b-form-group>
								<div
									slot="label"
									class="d-flex justify-content-between align-items-end"
								>
									<div>Password</div>
									<!-- <a href="javascript:void(0)" class="d-block small">Forgot password?</a> -->
								</div>
								<b-input
									type="password"
									v-on:keyup.enter.native="doAuth"
									v-model="credentials.password"
								/>
							</b-form-group>

							<div
								class="d-flex justify-content-between align-items-center m-0"
							>
								<!-- <b-check v-model="credentials.rememberMe" class="m-0">Remember me</b-check> -->
								<b-btn variant="primary" @click="doAuth"
									>Entrar</b-btn
								>
							</div>
						</form>
						<div class="w-100">
							<div class="d-table ml-auto">
								<small>ver 1.9.1</small>
							</div>
						</div>

						<!-- / Form -->

						<!-- <div class="text-center text-muted">
              Don't have an account yet? <a href="javascript:void(0)">Sign Up</a>
            </div>-->
					</div>
				</div>
			</div>
			<!-- / Form container -->
		</div>
	</div>
</template>

<!-- Page -->
<style lang="scss">
@import "@/vendor/styles/pages/authentication.scss";
</style>

<script>
import swal from "sweetalert2";
import auth_service from "@/services/auth/auth";

export default {
	name: "pages-authentication-login-v3",
	metaInfo: {
		title: "Gestor de tarefas - Login",
	},
	data: () => ({
		credentials: {
			email: "",
			password: "",
			rememberMe: false,
		},
	}),
	methods: {
		doAuth(event) {
			auth_service.login(
				this.credentials,
				this.loggedIn,
				this.loggedInFail
			);
		},
		loggedIn(success) {
			this.$router.push({ name: "Dashboard" });
		},
		loggedInFail(success) {
			swal.fire(
				"Autenticação Falhou",
				"Não foi possivel autenticar com as credenciais fornecidas",
				"error"
			);
		},
	},
	created() {
		if (auth_service.isAuthenticated) {
			this.$router.push({
				name: "Dashboard",
			});
		} else {
			$EventBus.$emit("toggleLoading", false, "authentication vue");
		}
	},
};
</script>
