import Layout2 from "@/layout/Layout2";

import WorkspaceList from "@/pages/Workspace/WorkspaceList";
export default {
  // Layout 2
  path: "/",
  component: Layout2,
  children: [
    {
      name: "WorkspaceList",
      path: "workspace/",
      component: WorkspaceList,
    },
  ],
};
