<template>
  <b-navbar
    toggleable="lg"
    :variant="getLayoutNavbarBg()"
    class="layout-navbar align-items-lg-center px-0"
    style="height: 40px"
  >
    <b-navbar-toggle target="app-layout-navbar px-0"></b-navbar-toggle>
    <!-- Alteração do Font-size navbar (david & Nicoly) !-->
    <b-collapse is-nav id="app-layout-navbar">
      <b-navbar-nav style="font-size: 16px">
        <b-nav-item :to="{ name: 'MyTasks' }">
          <span class="app-brand-logo demo">
            <div class="ui-w-60">
              <div class="w-100 position-relative" style="padding-bottom: 100%">
                <img
                  class="w-100 h-100 position-absolute"
                  src="@/assets/img/logo.png"
                  height="50px"
                  @click="
                    layoutHelpers.isCollapsed()
                      ? toggleSidenav()
                      : $router.push({ name: 'ProjectList' })
                  "
                  style="cursor: pointer"
                />
              </div>
            </div>
          </span>
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'MyTasks' }"
          class="app-brand-text demo font-weight-normal"
          >Gestor de Tarefas</b-nav-item
        >
        <b-nav-item
          :to="{ name: 'RoutineTasksNav' }"
          :exact="true"
          style="padding-top: 0.3rem"
        >
          <i class="fa fa-location-arrow" style="padding-right: 0.2rem"></i>
          <span>Rotinas</span>
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'MyTasks' }"
          :exact="true"
          style="padding-top: 0.3rem"
        >
          <i class="ion ion-ios-albums" style="padding-right: 0.2rem"></i>
          <span>Tarefas</span>
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'UsersNotepad' }"
          :exact="true"
          style="padding-top: 0.3rem"
        >
          <i class="fa fa-pencil-alt" style="padding-right: 0.2rem"></i>
          <span>Bloco de notas</span>
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'UsersBrainstorm' }"
          :exact="true"
          style="padding-top: 0.3rem"
        >
          <i class="fa fa-table" style="padding-right: 0.2rem"></i>
          <span>Brainstorm</span>
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'Dashboard' }"
          :exact="true"
          style="padding-top: 0.3rem"
        >
          <i class="ion ion-md-speedometer" style="padding-right: 0.2rem"></i>
          <span>Dashboard</span>
        </b-nav-item>
      </b-navbar-nav>
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2" />

      <b-navbar-nav class="align-items-lg-center ml-auto">
        <b-nav-item-dropdown
          no-caret
          :right="!isRTL"
          class="demo-navbar-user"
          @hidden="read"
        >
          <template slot="button-content">
            <span
              class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
            >
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0"
                >Workspace:
                {{
                  shared.activeWorkspace.none_selected
                    ? "Nenhum Selecionado"
                    : shared.activeWorkspace.name
                }}</span
              >
            </span>
          </template>

          <b-dd-item
            @click="$set(shared, 'activeWorkspace', workspace)"
            v-for="workspace in workspaces"
            :key="workspace.id"
            >Workspace: {{ workspace.name }}</b-dd-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          no-caret
          :right="!isRTL"
          class="demo-navbar-notifications"
          @hidden="read"
        >
          <template slot="button-content">
            <div class="text-success">
              <i
                :class="{
                  'ion ion-md-notifications-outline navbar-icon align-middle': true,
                }"
              ></i>
              <span
                class="badge badge-danger badge-dot indicator"
                v-if="unread"
                style="width: 0.8rem; height: 0.8rem"
                >{{ unread }}</span
              >
              <span class="d-lg-none align-middle">&nbsp; Notifications</span>
            </div>
          </template>

          <div
            class="bg-primary text-center text-white font-weight-bold p-3"
            v-if="unread"
          >
            {{ unread }} Novas notificações
          </div>
          <b-list-group flush>
            <b-list-group-item
              class="media d-flex align-items-center"
              v-for="(notification, index) in notifications"
              :key="index"
            >
              <div
                class="ui-icon ui-icon-sm ion ion-md-home bg-secondary border-0 text-white"
              ></div>
              <div class="media-body line-height-condenced ml-3">
                <div class="text-dark">{{ notification.data.title }}</div>
                <div class="text-light small mt-1">
                  {{ notification.data.text }}
                </div>
                <div class="text-light small mt-1">
                  {{ notification.created_at | time_from }}
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-nav-item-dropdown>

        <b-nav-item
          :to="{ name: 'Chat' }"
          :exact="true"
          @click="unread_messages = 0"
        >
          <div class="text-info">
            <i
              class="ion ion-md-chatbubbles"
              :class="{ 'animate-shake-chat': unread_messages }"
            ></i>
            <span
              class="badge badge-primary badge-dot indicator"
              v-if="unread_messages"
            ></span>
          </div>
        </b-nav-item>

        <!-- Divider -->
        <div
          class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25"
        >
          |
        </div>

        <b-nav-item-dropdown :right="!isRTL" class="demo-navbar-user">
          <template slot="button-content">
            <span
              class="d-inline-flex flex-lg-row-reverse align-items-center align-middle"
            >
              <img
                :src="user.photo"
                alt
                class="d-block ui-w-30 rounded-circle"
              />
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0" style="width: 140px"
                >{{ user.name }} {{ user.account_type == 0 ? "" : "" }}</span
              >
            </span>
          </template>

          <b-dd-item
            :to="{ name: 'UsersView', params: { id: 'me' } }"
            style="width: 200px"
          >
            <i class="ion ion-ios-person text-light"></i> &nbsp; Meu perfil
          </b-dd-item>
          <b-dd-divider />
          <b-dd-item
            :to="{ name: 'Audit' }"
            v-if="user.account_type == 0"
            style="width: 200px"
          >
            <i class="ion ion-md-warning text-light"></i> &nbsp; Auditoria
          </b-dd-item>
          <b-dd-item :to="{ name: 'Config' }" v-if="user.account_type <= 1">
            <i class="ion ion-md-settings text-light"></i> &nbsp; Configurações
          </b-dd-item>
          <b-dd-item :to="{ name: 'RoutineTasks' }" style="color: #d20110">
            <i
              class="fa fa-clock"
              style="color: #d20110; float: left; margin-top: 5px"
            ></i>
            <p
              style="
                margin-bottom: 0;
                font-size: 0.894rem;
                color: #d20110;
                float: rignt;
                margin: 0;
                padding-left: 17px;
                width: 150px;
              "
            >
              Cadastro de Rotinas
            </p>
          </b-dd-item>
          <b-dd-item :to="{ name: 'License' }" v-if="user.account_type == 0">
            <i class="ion ion-md-cloud-done text-light"></i> &nbsp; Licenças
          </b-dd-item>
          <b-dd-item
            :to="{ name: 'Report' }"
            v-if="(user.account_type == 0) | (user.account_type == 1)"
          >
            <i class="ion ion-md-filing text-light"></i> &nbsp; Relatórios
          </b-dd-item>
          <b-dd-item
            :to="{ name: 'UsersList' }"
            v-if="(user.account_type == 0) | (user.account_type == 1)"
          >
            <i class="ion ion-md-people text-light"></i> &nbsp; Usuários
          </b-dd-item>
          <b-dd-item
            :to="{ name: 'WorkspaceList' }"
            v-if="(user.account_type == 0) | (user.account_type == 1)"
          >
            <i class="ion ion-ios-photos text-light"></i> &nbsp; Workspaces
          </b-dd-item>
          <b-dd-divider />
          <b-dd-item :href="manual" target="_blank">
            <i class="ion ion-md-book text-light"></i> &nbsp; Manual
          </b-dd-item>
          <b-dd-divider />
          <b-dd-item @click="logout">
            <i class="ion ion-ios-log-out text-danger"></i> &nbsp; Sair
          </b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<style scoped>
.list-group-item {
  background-color: transparent !important;
  border-color: #f1f1f2 !important;
}

.animate-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: infinite;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.animate-shake-chat {
  animation: shake-chat 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: infinite;
  transform: translate3d(0, 0, 0);
}

@keyframes shake-chat {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>

<script>
import auth_service from "@/services/auth/auth";
import users from "@/services/auth/users";
import notifications from "@/services/auth/notifications";
import manual from "@/assets/MANUAL DO USUÁRIO.pdf";

import chat_service from "@/services/chat/chat";
import workspace_service from "@/services/general/workspace";
import message_service from "@/services/chat/messages";
import license_service from "@/services/general/license";

import swal from "sweetalert2";

import "@/utils/event-bus";
import shared from "@/utils/shared";

export default {
  name: "app-layout-navbar",

  data() {
    return {
      manual,
      shared,
      user: auth_service.getUserInformation(),
      notifications_key: "",
      notifications: [],
      unread_messages: 0,
    };
  },

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    license_service.all().then((resp) => {
      resp.forEach((license) => {
        if (license.module == "gestor") {
          if (license.valid) {
            this.refresh();
          } else {
            swal
              .fire({
                title: "Expirado",
                text: "A sua licença está expirada, por favor contate o seu consultor",
                type: "error",
              })
              .then(() => {
                this.logout();
              });
          }
        }
      });
    });
  },
  filters: {
    time_from(obj) {
      let today = new Date();

      let result = today - new Date(Number(obj) * 1000);

      result = result / 1000;

      if (result < 60) {
        return `Cerca de ${Math.round(result)} Segundos`;
      }

      result /= 60;

      if (result < 60) {
        return `Cerca de ${Math.round(result)} Minutos`;
      }

      result /= 60;

      if (result < 24) {
        return `Cerca de ${Math.round(result)} Horas`;
      }

      result /= 24;

      if (result < 30) {
        return `Cerca de ${Math.round(result)} Dias`;
      }

      result /= 30;

      if (result < 12) {
        return `Cerca de ${Math.round(result)} Meses`;
      }

      result /= 12;

      return `Cerca de ${Math.round(result)} Anos`;
    },
  },
  computed: {
    unread() {
      let count = 0;
      this.notifications.forEach((element) => {
        if (element.read == false) {
          count++;
        }
      });
      return count;
    },
    workspaces() {
      let workspaces = shared.workspaces;
      return workspaces;
    },
  },
  methods: {
    refresh() {
      users.request_notification_key(this.request_notification_key_callback);
      notifications.all(this.notifications_callback);
      chat_service
        .find({ workspace: shared.activeWorkspace.id })
        .catch(() => {})
        .then((resp) => {
          if (resp) {
            resp.forEach((chat) => {
              message_service.find({ to: chat.id, limit: 1 }).then((resp) => {
                if (
                  resp.results.length &&
                  !resp.results[0].user_read.includes(this.user.id)
                ) {
                  this.unread_messages++;
                }
              });
            });
          }
        });
      workspace_service.all().then((resp) => {
        //doing this we maintain the pointer to shared.workspaces
        this.$set(shared, "workspaces", resp);
        if (shared.activeWorkspace.none_selected) {
          shared.workspaces.forEach((workspace) => {
            if (workspace.default) {
              this.$set(shared, "activeWorkspace", workspace);
            }
          });
        }
      });
    },
    request_notification_key_callback(data) {
      if (this.$socket.isReady == 1) {
        this.$socket.sendObj({
          notification_key: this.notifications_key,
        });
      }
      this.notifications_key = data.key;
    },
    notifications_callback(data) {
      this.notifications = [];
      let notifications = this.notifications;

      data.forEach((element) => {
        notifications.push(element);
      });
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    },
    logout() {
      auth_service.logout();
      this.$router.push("/");
    },
    read() {
      let notifications = this.notifications;

      notifications.forEach((element) => {
        this.$socket.sendObj({
          read: element.id,
        });
        element.read = true;
      });
    },
  },
  sockets: {
    onmessage(message) {
      message = JSON.parse(message.data);
      // console.log(message);
      if (message.type == "user.notify") {
        this.$notify({
          group: "notifications-default",
          title: message.data.title,
          text: message.data.text,
        });
        this.refresh();
      } else {
        $EventBus.$emit("onsocketmessage", message);
      }
    },
    onopen() {
      this.$socket.sendObj({
        notification_key: this.notifications_key,
      });
    },
  },
  watch: {
    notifications_key(a, b) {
      this.$socket.sendObj({
        notification_key: this.notifications_key,
      });
    },
    "shared.activeWorkspace": {
      handler(a) {
        localStorage.activeWorspace = JSON.stringify(a);
        // console.log("shared.activeWorkspace");
      },
    },
  },
  destroyed() {
    // console.log("Destroyed");
  },
};
</script>
