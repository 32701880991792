
import Layout2 from '@/layout/Layout2'
import Audit from '@/pages/Audit/Audit'

export default {
    component: Layout2,
    path: '/Audit',
    children: [
        {
            path: '',
            name: 'Audit',
            component: Audit
        }
    ]
}