<template>
  <div class="px-5">
    <h4
      class="d-flex justify-content-between align-items-center w-100 font-weight-bold my-3"
    >
      <div>Configuração</div>
    </h4>

    <b-card :key="component_key">
      <PAreaList @refresh="refresh"></PAreaList>
      <hr />
      <PSectorList @refresh="refresh"></PSectorList>
    </b-card>
  </div>
</template>
<script>
import loading from "@/utils/loading.js";
import utils from "@/utils/utils";
import shared from "@/utils/shared";
export default {
  name: "PConfig",
  metaInfo: {
    title: "Configuração",
  },
  components: {
    PSectorList: () => import("@/pages/Sector/SectorList"),
    PAreaList: () => import("@/pages/Area/AreaList"),
  },
  data() {
    return {
      loading,
      shared,
      component_key: utils.uuidv4(),
    };
  },
  methods: {
    refresh() {
      this.$set(this, "component_key", utils.uuidv4());
    },
  },
  watch: {
    "shared.activeWorkspace": {
      handler() {
        this.refresh();
      },
    },
  },
};
</script>
