<template>
  <div class="px-5">
    <h4 class="font-weight-bold py-3 mb-4">Novo Usuário</h4>

    <b-tabs class="nav-tabs-top nav-responsive-sm">
      <b-tab title="Conta" active>
        <b-card-body>
          <div class="media align-items-center">
            <img
              alt
              class="d-block ui-w-80"
              v-if="userData.picture_thumb == ''"
              src="@/assets/img/placeholder-user.png"
            />
            <img
              :src="userData.picture_thumb"
              alt
              class="d-block ui-w-80"
              v-else
            />
            <div class="media-body ml-3">
              <label class="form-label d-block mb-2">Foto</label>
              <b-btn variant="outline-primary" size="sm" v-b-modal.modals-file
                >Mudar</b-btn
              >&nbsp;
              <!-- <b-btn variant="default md-btn-flat" size="sm">Reset</b-btn> -->
            </div>
          </div>
        </b-card-body>
        <hr class="border-light m-0" />
        <b-card-body class="pb-2">
          <b-form-group label="Nome">
            <b-input v-model="userData.first_name" />
          </b-form-group>

          <b-form-group label="Sobrenome">
            <b-input v-model="userData.last_name" />
          </b-form-group>

          <b-form-group label="Email">
            <b-input v-model="userData.email" class="mb-1" />
          </b-form-group>

          <!-- <b-form-group label="Digite a senha de acesso do novo usuário">
            <b-input v-model="userData.password_1" class="mb-1" :type="'password'" />
            <password v-model="userData.password_1" :strength-meter-only="true" />
          </b-form-group>

          <b-form-group label="Digite a senha novamente">
            <b-input v-model="userData.password_2" class="mb-1" :type="'password'" />
            <password v-model="userData.password_2" :strength-meter-only="true" />
          </b-form-group>-->
        </b-card-body>
        <hr class="border-light m-0" />
        <b-card-body class="pb-2">
          <h6 class="mb-4">Gerenciamento de contas</h6>
          <b-form-group label="Tipo de usuário">
            <b-select
              v-model="userData.account_type"
              :options="account_types"
              :disabled="logged_user.account_type != 0"
            />
          </b-form-group>
          <!--<b-form-group label>
            <b-form-checkbox
              v-model="userData.can_create"
              class="mb-1"
            >Usuário será capaz de criar novas tarefas?</b-form-checkbox>
          </b-form-group>-->

          <b-form-group label="Area">
            <div
              class="mb-1 mr-1 d-inline-block"
              v-for="a in userData.area"
              :key="a.id"
            >
              <b-btn
                size="sm"
                variant="primary"
                @click="
                  userData.area.splice(userData.area.indexOf(r), 1);
                  data.item.changed.responsible = true;
                "
              >
                {{ a.name }}
                <i class="ion ion-md-close"></i>
              </b-btn>
            </div>
            <customTypeahead
              v-model="userData.area"
              :options="areas"
              :objKey="'name'"
              :add="true"
              :multiple="true"
            />
          </b-form-group>

          <b-form-group label="Area/Setor">
            <div
              class="mb-1 mr-1 d-inline-block"
              v-for="s in userData.sector"
              :key="s.id"
            >
              <b-btn
                size="sm"
                variant="primary"
                @click="
                  userData.sector.splice(userData.sector.indexOf(r), 1);
                  data.item.changed.responsible = true;
                "
              >
                {{ s.name }}
                <i class="ion ion-md-close"></i>
              </b-btn>
            </div>
            <customTypeahead
              v-model="userData.sector"
              :options="sectors"
              :objKey="'name'"
              :add="true"
              :multiple="true"
            />
          </b-form-group>

          <b-form-group label="Workspaces">
            <div
              class="mb-1 mr-1 d-inline-block"
              v-for="s in userData.workspaces"
              :key="s.id"
            >
              <b-btn
                size="sm"
                variant="primary"
                @click="
                  userData.workspaces.splice(userData.workspaces.indexOf(s), 1);
                  data.item.changed.workspaces = true;
                "
              >
                {{ s.name }}
                <i class="ion ion-md-close"></i>
              </b-btn>
            </div>
            <customTypeahead
              v-model="userData.workspaces"
              :options="workspaces"
              :objKey="'name'"
              :add="false"
              :multiple="true"
            />
          </b-form-group>
        </b-card-body>
        <hr class="border-light m-0" />
        <div class="table-responsive">
          <!-- <b-table :items="userData.permissions" class="card-table m-0">
              <b-check v-model="data.item.read" class="px-2 m-0" />
            <template slot="read" slot-scope="data">
            </template>
            <template slot="write" slot-scope="data">
              <b-check v-model="data.item.write" class="px-2 m-0" />
            </template>
            <template slot="create" slot-scope="data">
              <b-check v-model="data.item.create" class="px-2 m-0" />
            </template>
            <template slot="delete" slot-scope="data">
              <b-check v-model="data.item.delete" class="px-2 m-0" />
            </template>
          </b-table>-->
        </div>
        <hr class="border-light m-0" />
        <b-card-body class="pb-2">
          <h6 class="mb-4">Informação Pessoal</h6>

          <b-form-group label="Telefone">
            <b-input
              v-model="userData.phone"
              v-mask="['(##) ####-####', '(##) #####-####']"
            />
          </b-form-group>

          <!-- <b-form-group label="Aniversário">
            <datepicker
              v-model="userData.birthday"
              :bootstrapStyling="true"
              :monday-first="true"
              :full-month-name="true"
              placeholder="Insira a data de aniversário"
              :calendar-button="true"
              calendar-button-icon="ion ion-md-calendar"
              :clear-button="true"
              :language="languages[language]"
              :format="customFormatter"
            />
          </b-form-group>-->
        </b-card-body>
      </b-tab>
    </b-tabs>

    <div class="text-right mt-3">
      <b-btn variant="primary" @click="submitUpdate">Criar usuário</b-btn>&nbsp;
      <b-btn
        variant="default"
        @click="
          () => {
            this.$router.push({
              name: 'UsersView',
              params: { id: this.$route.params.id },
            });
          }
        "
        >Cancel</b-btn
      >
    </div>

    <b-modal id="modals-file" ref="modalFile">
      <template v-slot:modal-title>Adicionar arquivos:</template>
      <b-form-row>
        <b-form-group label="Nome" class="col">
          <!-- <b-input-file placeholder="" v-model="user_search" /> -->
          <b-form-file
            v-model="temp_file"
            :state="Boolean(temp_file)"
            placeholder="Choose a file..."
            drop-placeholder="Drop file here..."
          />
        </b-form-group>
      </b-form-row>
      <template v-slot:modal-footer>
        <b-btn @click="closeModalFile">Close</b-btn>
        <b-btn @click="uploadFile" :variant="'primary'">Enviar</b-btn>
      </template>
    </b-modal>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style
  src="@/vendor/libs/vue-multiselect/vue-multiselect.scss"
  lang="scss"
></style>

<!-- Page -->
<style src="@/vendor/styles/pages/users.scss" lang="scss"></style>

<script>
import auth_service from "@/services/auth/auth";
import area_service from "@/services/general/areas";
import sectors_service from "@/services/general/sectors";
import users_service from "@/services/auth/users";
import * as lang from "vuejs-datepicker/src/locale";
import workspaces_service from "@/services/general/workspace";
import loading from "@/utils/loading";
import shared from "@/utils/shared";
import Swal from "sweetalert2";

export default {
  name: "pages-user-edit",
  metaInfo: {
    title: "Novo Usuário",
  },
  components: {
    Multiselect: () => import("vue-multiselect"),
    Datepicker: () => import("vuejs-datepicker"),
    customTypeahead: () => import("@/components/Utils/CustomTypeahead.vue"),
    Password: () => import("vue-password-strength-meter"),
  },
  data: () => ({
    shared,
    languages: lang,
    language: "ptBR",
    account_types: {
      0: "Diretor",
      1: "Gestor",
      2: "Colaborador",
    },
    logged_user: auth_service.getUserInformation(),
    userData: Object.assign({}, users_service.base_object),
    temp_file: "",
    areas: [],
    sectors: [],
    workspaces: [],

    done: {
      default: false,
    },
  }),
  created() {
    this.refresh();
  },
  methods: {
    user_find_callback(resp) {
      this.userData = resp;
      if (
        this.logged_user.account_type != 0 &&
        this.logged_user.id != this.userData.id
      ) {
        this.$router.push({
          name: "UsersView",
          params: {
            id: this.userData.id,
          },
        });
      }
    },
    closeModalFile() {
      this.$bvModal.hide("modals-file");
    },
    uploadFile() {
      if (this.$route.params.id) {
        users_service.upload_picture(this.temp_file, this.uploadDone);
      } else {
        function getBase64(self, file, callback) {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            callback(self, reader.result);
          };
          reader.onerror = function (error) {};
        }

        function _callback(self, result) {
          self.userData.picture_thumb = result;
          self.userData.picture = result;
          self.closeModalFile();
        }

        getBase64(this, this.temp_file, _callback);
      }
    },
    uploadDone(data) {
      if (data.error) {
        //swal falha
      } else {
        this.refresh();
        this.closeModalFile();
      }
    },
    refresh() {
      if (this.logged_user.account_type != 0) {
        this.$router.push({
          name: "UsersList",
        });
      }

      let area_service_loading = loading.addLoader("area_service_loading");
      area_service
        .find({ workspace: shared.activeWorkspace.id })
        .then((resp) => {
          this.areas = resp;
        })
        .finally(() => {
          area_service_loading.done();
        });

      let sectors_service_loading = loading.addLoader(
        "sectors_service_loading"
      );
      sectors_service
        .find({ workspace: shared.activeWorkspace.id })
        .then((resp) => {
          this.sectors = resp;
        })
        .then(() => {
          sectors_service_loading.done();
        });

      workspaces_service.all().then((resp) => {
        this.workspaces = resp;
      });
      loading.isDone();
    },
    submitUpdate() {
      let promises = [];
      let copy = JSON.parse(JSON.stringify(this.userData));
      if (copy.area.length) {
        copy.area.forEach((area) => {
          let index = copy.area.indexOf(area);
          if (!area.pk) {
            Swal.fire("Erro", "Area Invalida" + sector.name, "error");
          }
        });
      }
      if (copy.sector.length) {
        copy.sector.forEach((sector) => {
          let index = copy.sector.indexOf(sector);
          if (!sector.pk) {
            Swal.fire("Erro", "Area/Setor Invalido" + sector.name, "error");
          }
        });
      }

      copy.workspaces = copy.workspaces.map((workspace) => workspace.id);
      Promise.all(promises).then(() => {
        copy.area = copy.area.map((area) => area.pk);
        copy.sector = copy.sector.map((sector) => sector.pk);
        // copy.workspace = shared.activeWorkspace.id;
        users_service
          .add(copy, () => {
            Swal.fire("Sucesso!", "Usuário criado com sucesso", "success");
            this.$router.push({
              name: "UsersList",
            });
          })
          .then((resp) => {})
          .catch((error) => {
            Swal.fire("Falha", "Usuário já existe", "error");
          });
      });
    },
    submitUpdate_callback() {
      this.$router.push({
        name: "UsersList",
      });
    },
    customFormatter(date) {
      let day = this.zeroPadding(date.getDate(), 2);
      let month = this.zeroPadding(date.getMonth() + 1, 2);
      return `${day}/${month}`;
    },
    zeroPadding(obj, size) {
      if (typeof obj != "string") {
        obj = obj.toString();
      }

      while (obj.length < size) {
        obj = "0" + obj;
      }
      return obj;
    },
  },
};
</script>
