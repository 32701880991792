
import Layout2 from '@/layout/Layout2'
import MyTasks from '@/pages/Tasks/MyTasks';

export default {
    // Layout 2
    path: '/',
    component: Layout2,
    children: [
        {
            name: "MyTasks",
            path: "tasks",
            component: MyTasks
        }
    ]
}