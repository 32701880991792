import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=68597bfb&scoped=true"
import script from "./Chat.vue?vue&type=script&lang=js"
export * from "./Chat.vue?vue&type=script&lang=js"
import style0 from "@/vendor/styles/pages/chat.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "@/vendor/libs/vue-multiselect/vue-multiselect.scss?vue&type=style&index=2&prod&lang=scss&external"
import style3 from "./Chat.vue?vue&type=style&index=3&id=68597bfb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68597bfb",
  null
  
)

export default component.exports