<template>
  <sidenav
    :orientation="orientation"
    :class="curClasses"
    :showDropdownOnHover="false"
  >
    <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      <span class="app-brand-logo demo">
        <div class="ui-w-60">
          <div class="w-100 position-relative" style="padding-bottom: 100%">
            <img
              class="w-100 h-100 position-absolute"
              src="@/assets/img/logo.png"
              height="50px"
              @click="
                layoutHelpers.isCollapsed()
                  ? toggleSidenav()
                  : $router.push({ name: 'ProjectList' })
              "
              style="cursor: pointer"
            />
          </div>
        </div>
      </span>
      <router-link
        :to="{ name: 'MyTasks' }"
        class="app-brand-text demo sidenav-text font-weight-normal ml-2"
        >Gestor de Tarefas</router-link
      >
      <a
        href="javascript:void(0)"
        class="layout-sidenav-toggle sidenav-link text-large ml-auto"
        @click="toggleSidenav()"
      >
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

    <!-- Links -->
    <div
      class="sidenav-inner"
      :class="{ 'py-1': orientation !== 'horizontal' }"
    >
      <!-- Dashboards -->
      <sidenav-router-link
        icon="ion ion-md-speedometer"
        to="/dashboard"
        :exact="true"
        >Dashboard</sidenav-router-link
      >
      <!-- <sidenav-menu icon="ion ion-md-speedometer" :active="isMenuActive('/dashboards')" :open="isMenuOpen('/dashboards')">
        <template slot="link-text">Dashboards</template>
        <sidenav-router-link to="/dashboards/dashboard-1" :exact="true">Dashboard 1</sidenav-router-link>
        <sidenav-router-link to="/dashboards/dashboard-2" :exact="true">Dashboard 2</sidenav-router-link>
        <sidenav-router-link to="/dashboards/dashboard-3" :exact="true">Dashboard 3</sidenav-router-link>
        <sidenav-router-link to="/dashboards/dashboard-4" :exact="true">Dashboard 4</sidenav-router-link>
        <sidenav-router-link to="/dashboards/dashboard-5" :exact="true">Dashboard 5</sidenav-router-link>
      </sidenav-menu>-->

      <!-- Projects -->
      <!-- <sidenav-menu
        icon="ion ion-ios-albums"
        :active="isMenuActive('/projects')"
        :open="isMenuOpen('/projects')"
      >
        <template slot="link-text">
          <span @click="(event) => {this.$router.push({name:'MyTasks'})}">Tarefas</span>
        </template>
      </sidenav-menu>-->

      <sidenav-router-link
        icon="ion ion-ios-albums"
        :to="{ name: 'MyTasks' }"
        :exact="true"
        >Tarefas</sidenav-router-link
      >

      <!-- Tasks -->
      <sidenav-router-link
        icon="ion ion-md-checkbox-outline"
        :to="{ name: 'MyTasks' }"
        :exact="true"
        v-if="false"
        >Minhas sub-tarefas</sidenav-router-link
      >

      <!-- Reports -->
      <sidenav-menu
        icon="ion ion-md-pie"
        :active="isMenuActive('/charts')"
        :open="isMenuOpen('/charts')"
        @click.native="layoutHelpers.isCollapsed() ? toggleSidenav() : () => {}"
      >
        <template slot="link-text">Relatorios</template>
        <!-- <sidenav-router-link to="/charts/vue2-google-maps" :exact="true">Vue2 Google Maps</sidenav-router-link>
        <sidenav-router-link to="/charts/vue-c3" :exact="true">Vue C3</sidenav-router-link>
        <sidenav-router-link to="/charts/vue-echarts" :exact="true">Vue Echarts</sidenav-router-link>
        <sidenav-router-link to="/charts/vue-chartjs" :exact="true">Vue Chartjs</sidenav-router-link>
        <sidenav-router-link to="/charts/vue-trend" :exact="true">Vue Trend</sidenav-router-link>-->
      </sidenav-menu>

      <sidenav-divider class="mb-1" />
      <sidenav-header class="small font-weight-semibold"
        >CONFIGURAÇÕES</sidenav-header
      >

      <sidenav-router-link
        icon="ion ion-md-quote"
        :to="{ name: 'UsersList' }"
        :exact="true"
        >Usuários</sidenav-router-link
      >
    </div>
  </sidenav>
</template>

<script>
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider,
} from "@/vendor/libs/sidenav";

import tasks from "@/services/tasks/tasks.js";

export default {
  name: "app-layout-sidenav",
  components: {
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,
  },
  data() {
    return {
      tasks: [],
    };
  },

  props: {
    orientation: {
      type: String,
      default: "vertical",
    },
  },

  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return (
        `bg-${bg} sidenav-keep-collapsed ` +
        (this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    },
  },
  created() {
    this.refresh();
  },

  methods: {
    refresh() {
      tasks.find({ workspace: shared.activeWorkspace.id }).then((resp) => {
        this.tasks = resp;
      });
    },
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
  },
};
</script>
