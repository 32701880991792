<template>
  <div>
    <h4
      class="d-flex justify-content-between align-items-center w-100 font-weight-bold py-3 mb-1"
      :style="{ opacity: show ? 1 : 0 }"
    >
      <div>
        Olá {{ logged_user.name }},
        {{
          !shared.activeWorkspace.none_selected
            ? "nós temos"
            : "por favor selecione um workspace"
        }}
      </div>
    </h4>
    <div class="float-right">
      <span class="mr-1 h4">Tarefas</span>
      <label class="switcher mr-0">
        <input
          type="checkbox"
          class="switcher-input"
          v-model="load_routines"
          @change="refresh()"
        />
        <span
          class="switcher-indicator"
          style="background: rgba(24, 28, 33, 0.1)"
        >
          <span class="switcher-yes"></span>
          <span class="switcher-no"></span>
        </span>
      </label>
      <span class="h4 ml-1">Rotinas</span>
    </div>
    <b-row
      class="mb-3"
      v-if="!shared.activeWorkspace.none_selected"
      :key="load_routines"
    >
      <b-col>
        <b-card
          class="text-lightest"
          style="
            min-height: 130px;
            cursor: pointer;
            background-color: #dc202e;
            font-weight: bold;
            font-size: 18px;
          "
          :style="{ opacity: dashboardData.delayed.today != undefined ? 1 : 0 }"
          @click="
            () => {
              this.help_text = `${
                this.load_routines ? 'Rotinas' : 'Tarefas'
              } das quais você esta envolvido que estão vencidas`;

              let today = new Date();
              findTasks({
                expected_finish_date__lt: utils.format_date(today),
                status: '0,1',
              });
            }
          "
          v-b-tooltip.window="'Clique para ver as tarefas vencidas'"
        >
          <b-row>
            <b-col>
              <p
                :style="{
                  opacity: dashboardData.delayed.today != undefined ? 1 : 0,
                }"
                class="mb-0"
                v-if="this.load_routines"
              >
                <span class="display-4">{{ dashboardData.delayed.today }}</span>
                {{
                  dashboardData.delayed.today != 1
                    ? `rotinas que ja venceram`
                    : `rotina que ja venceu`
                }}
              </p>
              <p
                :style="{
                  opacity: dashboardData.delayed.today != undefined ? 1 : 0,
                }"
                class="mb-0"
                v-else
              >
                <span class="display-4">{{ dashboardData.delayed.today }}</span>
                {{
                  dashboardData.delayed.today != 1
                    ? `tarefas que ja venceram`
                    : `tarefa que ja venceu`
                }}
              </p>
            </b-col>
            <b-col>
              <div style="position: relative">
                <i
                  class="display-1 far fa-chart-bar"
                  style="position: absolute; right: 0px; top: calc(0px + 38px)"
                ></i>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          class="text-lightest"
          style="
            min-height: 130px;
            cursor: pointer;
            background-color: #edd818;
            color: white !important;
            font-weight: bold;
            font-size: 18px;
          "
          :style="{
            opacity: dashboardData.delayed.thisWeek != undefined ? 1 : 0,
          }"
          @click="
            () => {
              this.help_text = `${
                this.load_routines ? 'Rotinas' : 'Tarefas'
              } das quais você esta envolvido que estão para vencer nesta semana`;
              let today = new Date();
              let nextWeek = new Date();
              nextWeek.setDate(today.getDate() + 7);
              findTasks({
                expected_finish_date__lte: utils.format_date(nextWeek),
                expected_finish_date__gte: utils.format_date(today),
                status: '0,1',
              });
            }
          "
          v-b-tooltip.window="
            'Clique para ver as tarefas que estão para vencer nesta semana'
          "
        >
          <b-row>
            <b-col>
              <p
                :style="{
                  opacity: dashboardData.delayed.thisWeek != undefined ? 1 : 0,
                }"
                class="mb-0"
                v-if="this.load_routines"
              >
                <span class="display-4">{{
                  dashboardData.delayed.thisWeek
                }}</span>
                {{
                  !(dashboardData.delayed.thisWeek == 1) ? "rotinas" : "rotina"
                }}
                a vencer nesta semana.
              </p>
              <p
                :style="{
                  opacity: dashboardData.delayed.thisWeek != undefined ? 1 : 0,
                }"
                class="mb-0"
                v-else
              >
                <span class="display-4">{{
                  dashboardData.delayed.thisWeek
                }}</span>
                {{
                  !(dashboardData.delayed.thisWeek == 1) ? "tarefas" : "tarefa"
                }}
                a vencer nesta semana.
              </p>
            </b-col>
            <b-col>
              <div style="position: relative">
                <i
                  class="display-1 far fa-chart-bar"
                  style="position: absolute; right: 0px; top: calc(0px + 38px)"
                ></i>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          class="bg-success-dark text-lightest"
          style="
            min-height: 130px;
            cursor: pointer;
            background-color: #00b327 !important;
            color: white !important;
            font-weight: bold;
            font-size: 18px;
          "
          :style="{
            opacity: dashboardData.delayed.thisMonth != undefined ? 1 : 0,
          }"
          @click="
            () => {
              this.help_text = `${
                this.load_routines ? 'Rotinas' : 'Tarefas'
              }s das quais você esta envolvido que estão dentro do prazo`;
              let today = new Date();
              let thisMonth = new Date();
              thisMonth.setMonth(today.getMonth() + 1);
              thisMonth.setDate(0);
              findTasks({
                expected_finish_date__lte: utils.format_date(thisMonth),
                expected_finish_date__gte: utils.format_date(today),
                status: '0,1',
              });
            }
          "
          v-b-tooltip.window="
            'Clique para ver as tarefas que estão dentro do prazo'
          "
        >
          <b-row>
            <b-col>
              <p
                :style="{
                  opacity: dashboardData.delayed.thisMonth != undefined ? 1 : 0,
                }"
                class="mb-0"
                v-if="this.load_routines"
              >
                <span class="display-4">{{
                  dashboardData.delayed.thisMonth
                }}</span>
                {{
                  !(dashboardData.delayed.thisMonth == 1) ? "rotinas" : "rotina"
                }}
                dentro do prazo
              </p>
              <p
                :style="{
                  opacity: dashboardData.delayed.thisMonth != undefined ? 1 : 0,
                }"
                class="mb-0"
                v-else
              >
                <span class="display-4">{{
                  dashboardData.delayed.thisMonth
                }}</span>
                {{
                  !(dashboardData.delayed.thisMonth == 1) ? "tarefas" : "tarefa"
                }}
                dentro do prazo
              </p>
            </b-col>
            <b-col>
              <div style="position: relative">
                <i
                  class="display-1 far fa-chart-bar"
                  style="position: absolute; right: 0px; top: calc(0px + 38px)"
                ></i>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          class="bg-info-dark text-lightest"
          style="
            min-height: 130px;
            cursor: pointer;
            background-color: #04d5c8 !important;
            color: white !important;
            font-weight: bold;
            font-size: 17px;
          "
          :style="{
            opacity: dashboardData.recent.updated != undefined ? 1 : 0,
          }"
          @click="
            () => {
              this.help_text =
                'Tarefas das quais você esta envolvido que foram modificadas';
              this.tasksData = this.logs.map((e) => e.object_pk);
              this.processTasksData();
            }
          "
          v-b-tooltip.window="
            'Clique para ver as tarefas que foram modificadas'
          "
        >
          <b-row>
            <b-col>
              <p
                :style="{
                  opacity: dashboardData.recent.updated != undefined ? 1 : 0,
                }"
                class="mb-0"
                v-if="this.load_routines"
              >
                <span class="display-4">{{
                  dashboardData.recent.updated
                }}</span>
                {{
                  dashboardData.recent.updated != 1
                    ? `rotinas foram modificadas`
                    : `rotina foi modificada`
                }}
                recentemente
              </p>
              <p
                :style="{
                  opacity: dashboardData.recent.updated != undefined ? 1 : 0,
                }"
                class="mb-0"
                v-else
              >
                <span class="display-4">{{
                  dashboardData.recent.updated
                }}</span>
                {{
                  dashboardData.recent.updated != 1
                    ? `tarefas foram modificadas`
                    : `tarefa foi modificada`
                }}
                recentemente
              </p>
            </b-col>
            <b-col>
              <div style="position: relative">
                <i
                  class="display-1 far fa-chart-bar"
                  style="position: absolute; right: 0px; top: calc(0px + 38px)"
                ></i>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <DashboadCardTable
      :tasksData="tasksData"
      :table_key="table_key"
      v-if="!shared.activeWorkspace.none_selected"
      :help_text="help_text"
      v-model="only_users"
      >></DashboadCardTable
    >
  </div>
</template>

<style scoped>
div.card,
div.card * {
  transition: opacity 1s;
  transition-timing-function: ease;
}

div.card-body div.row div.col:first-child {
  min-width: 80% !important;
}
</style>

<script>
import utils from "@/utils/utils";
import loading from "@/utils/loading";
import shared from "@/utils/shared";

import tasks_service from "@/services/tasks/tasks";
import routine_tasks_service from "@/services/tasks/routine";
import logs_service from "@/services/tasks/logs";
import auth_service from "@/services/auth/auth";
import users_service from "@/services/auth/users";
import area_service from "@/services/general/areas";
import sectors_service from "@/services/general/sectors";
import guidelines_service from "@/services/tasks/guidelines";
import * as moment from "moment";

function render_obj(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export default {
  name: "pages-projects-list",

  metaInfo: {
    title: "Dashboard",
  },

  components: {
    CcDashboardChart: () => import("@/components/Dashboard/Chart"),
    DashboadCardTable: () => import("@/components/Dashboard/CardTable"),
  },

  data() {
    return {
      log: console.log,
      shared,
      utils,
      loading,
      logged_user: auth_service.getUserInformation(),
      dashboardData: {
        delayed: {
          today: undefined,
          thisWeek: undefined,
          thisMonth: undefined,
          nextMonth: undefined,
        },
        total: {
          important: undefined,
          normal: undefined,
          low: undefined,
          closed: undefined,
          open: undefined,
          concluded: undefined,
          total: undefined,
        },
        recent: {
          updated: undefined,
        },
      },
      tasksData: [],
      logs: [],
      show: false,
      table_key: utils.uuidv4(),
      help_text:
        "Tarefas das quais você esta envolvido que foram modificadas recentemente",
      only_users: false,
      common_params: {},
      load_routines: false,
      service: tasks_service,
    };
  },

  created() {
    this.refresh();
  },

  methods: {
    refresh() {
      this.service = this.load_routines ? routine_tasks_service : tasks_service;
      let today = new Date();
      let nextWeek = new Date();
      nextWeek.setDate(today.getDate() + 7);
      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      let thisMonth = new Date();
      thisMonth.setMonth(today.getMonth() + 1);
      thisMonth.setDate(0);
      let nextMonth = new Date();
      nextMonth.setMonth(today.getMonth() + 2);
      nextMonth.setDate(0);
      this.common_params = {
        complex_or_filter: `${
          !this.only_users ? `contributors="${this.logged_user.id}",` : ""
        }responsible="${this.logged_user.id}"`,
        subtasks: true,
      };
      let data = [
        {
          identifier: "delayed_tasks",
          key: "delayed.today",
          params: {
            expected_finish_date__lt: utils.format_date(today),
            status: "0,1",
          },
          common_params: this.common_params,
        },
        {
          identifier: "delayed_tasks_10",
          key: "delayed.thisWeek",
          params: {
            expected_finish_date__lte: utils.format_date(nextWeek),
            expected_finish_date__gte: utils.format_date(today),
            status: "0,1",
          },
          common_params: this.common_params,
        },
        {
          identifier: "delayed_tasks_30",
          key: "delayed.thisMonth",
          params: {
            expected_finish_date__lte: utils.format_date(thisMonth),
            expected_finish_date__gte: utils.format_date(today),
            status: "0,1",
          },
          common_params: this.common_params,
        },
        {
          identifier: "delayed_tasks_60",
          key: "delayed.nextMonth",
          params: {
            expected_finish_date__lte: utils.format_date(nextMonth),
            expected_finish_date__gte: utils.format_date(thisMonth),
            status: "0,1",
          },
          common_params: this.common_params,
        },
        {
          identifier: "total_tasks_total",
          key: "total.total",
          params: {},
          common_params: this.common_params,
        },
        {
          identifier: "total_tasks_important",
          key: "total.important",
          params: {
            priority: 1,
          },
          common_params: this.common_params,
        },
        {
          identifier: "total_tasks_normal",
          key: "total.normal",
          params: {
            priority: 2,
          },
          common_params: this.common_params,
        },
        {
          identifier: "total_tasks_low",
          key: "total.low",
          params: {
            priority: 3,
          },
          common_params: this.common_params,
        },
        {
          identifier: "total_tasks_closed",
          key: "total.closed",
          params: {
            status: 0,
          },
          common_params: this.common_params,
        },
        {
          identifier: "total_tasks_open",
          key: "total.open",
          params: {
            status: 1,
          },
          common_params: this.common_params,
        },
        {
          identifier: "total_tasks_concluded",
          key: "total.concluded",
          params: {
            status: 2,
          },
          common_params: this.common_params,
        },
      ];
      const vm = this;
      data.forEach((search, index) => {
        let dashboard_loading = loading.addLoader(
          `${search.identifier}_k-${search.key}`
        );
        // console.log("addLoader",`${search.identifier}_k-${search.key}`, dashboard_loading.id)
        this.service
          .find(
            Object.assign({ limit: 1 }, search.params, search.common_params),
            undefined,
            search.identifier + search.key
          )
          .then((resp) => {
            let keys = search.key.split(".");
            let element = vm.dashboardData;
            for (let j = 0; j < keys.length - 1; j++) {
              element = element[keys[j]];
            }
            element[keys[keys.length - 1]] = resp.count;
          })
          .finally(() => {
            dashboard_loading.done();
            vm.show = true;
          });
      });

      this.findTasks({
        expected_finish_date__lt: utils.format_date(today),
        status: "0,1",
      });
      this.help_text =
        (this.load_routines ? "Rotinas" : "Tarefas") +
        " das quais você esta envolvido que estão vencidas";

      logs_service
        .find({
          timestamp__gte: utils.format_date(lastWeek),
          timestamp__lte: utils.format_date(today),
          limit: 10,
        })
        .then((resp) => {
          let dummy_resp = {
            results: [],
          };
          let p = [];
          resp.results.forEach((result) => {
            let obj = result.object_pk;
            p.push(
              this.service.find({ id: obj }).then((resp) => {
                if (resp.length) {
                  dummy_resp.results.push(result);
                }
              })
            );
          });
          Promise.all(p).finally(() => {
            dummy_resp.count = dummy_resp.results.length;

            this.logs = dummy_resp.results;
            data.push({
              identifier: "recent_tasks_updated",
              key: "recent.updated",
              resp: dummy_resp,
            });
          });
        });
    },
    async processTasksData() {
      for (let index = 0; index < this.tasksData.length; index++) {
        const task = this.tasksData[index];
        let tasks_service_loading = loading.addLoader("tasks_service_loading");
        if (typeof task == "string") {
          await this.service
            .find({ id: task, subtasks: true })
            .then((t) => {
              if (t.length) {
                this.tasksData[index] = t[0];
                this.logs.forEach((log) => {
                  if (log.object_pk == t.id) {
                    this.tasksData[index].changes = JSON.parse(log.changes);
                  }
                });
              }
            })
            .finally(() => {
              tasks_service_loading.done();
            });
        }
      }
      let tasks_data_loading = loading.addLoader("processTasksData");
      let prefetch = {
        areas: new Set(this.tasksData.map((element) => element.area)),
        sectors: new Set(this.tasksData.map((element) => element.sector)),
        users: new Set(
          []
            .concat(this.tasksData.map((element) => element.creator))
            .concat(
              this.tasksData
                .map((element) => element.contributors)
                .reduce((acc, val) => acc.concat(val), [])
            )
            .concat(
              this.tasksData
                .map((element) => element.responsible)
                .reduce((acc, val) => acc.concat(val), [])
            )
        ),
      };

      let promises = [];
      prefetch.areas.forEach((area) => {
        promises.push(area_service.get(area).then((resp) => {}));
      });
      prefetch.sectors.forEach((sector) => {
        promises.push(
          sectors_service
            .get(sector)
            .then((resp) => {})
            .finally(() => {})
        );
      });
      prefetch.users.forEach((user) => {
        promises.push(
          users_service
            .get(user)
            .then((resp) => {})
            .finally(() => {})
        );
      });

      try {
        await Promise.all(promises.map(utils.reflect));
      } catch (error) {
        console.error(error);
      }

      this.tasksData.forEach(async (element) => {
        //used for changes control
        element.changed = {};
        let finish_date = this.load_routines ? element.finish_date : element.expected_finish_date;

        element.moment_expected_finish_date = moment(
          Number(finish_date) * 1000
        );
        element.expected_finish_date = new Date(
          Number(finish_date) * 1000
        );
        //remove auto-localization
        element.expected_finish_date.setHours(
          element.expected_finish_date.getHours() -
            element.expected_finish_date.getTimezoneOffset() / 60
        );

        //fetch creator data
        let creator_loading = loading.addLoader("creator_loading");
        users_service
          .get(element.creator)
          .then((resp) => {
            element.creator = resp;
            this.update_key();
            //this.deboucedRefreshTable();
          })
          .finally(() => {
            creator_loading.done();
          });

        //fetch area data
        let area_loading = loading.addLoader("area_loading");
        area_service
          .get(element.area)
          .then((resp) => {
            element.area = resp;
            this.update_key();
            //this.deboucedRefreshTable();
          })
          .finally(() => {
            area_loading.done();
          });
        //fetch sector data
        let sector_loading = loading.addLoader("sector_loading");
        sectors_service
          .get(element.sector)
          .then((resp) => {
            element.sector = resp;
            this.update_key();
            //this.deboucedRefreshTable();
          })
          .finally(() => {
            sector_loading.done();
          });

        //fetch guidelines data
        let guidelines_loading = loading.addLoader("guidelines_loading");
        element.guidelines.forEach((guideline) => {
          let guideline_loading = loading.addLoader("guideline_loading");

          guidelines_service
            .get(guideline)
            .then((resp) => {
              if (resp.creator) {
                let guideline_user_loading = loading.addLoader(
                  "guideline_user_loading"
                );
                if (typeof resp.creator != "string") {
                  resp.creator = resp.creator.id;
                }
                users_service
                  .get(resp.creator)
                  .then((user) => {
                    resp.creator = user;
                    let index = element.guidelines.indexOf(guideline);
                    if (index !== -1) {
                      element.guidelines[index] = resp;
                      // Como o código vue não percebe mudanças tão profundas reinstanciamos o objeto para que seja disparada a mudança
                      element.guidelines = Array.from(element.guidelines);
                    }
                    //this.deboucedRefreshTable();
                  })
                  .finally(() => {
                    guideline_user_loading.done();
                  });
              } else {
                let index = element.guidelines.indexOf(guideline);
                if (index !== -1) {
                  element.guidelines[index] = resp;
                  // Como o código vue não percebe mudanças tão profundas reinstanciamos o objeto para que seja disparada a mudança
                  element.guidelines = Array.from(element.guidelines);
                }
                //this.deboucedRefreshTable();
              }
            })
            .finally(() => {
              guideline_loading.done();
            });
        });
        guidelines_loading.done();

        //fetch contributors data
        let contributors_loading = loading.addLoader("contributors_loading");
        element.contributors.forEach((contributor) => {
          let contributor_loading = loading.addLoader("contributor_loading");
          users_service
            .get(contributor)
            .then((resp) => {
              let index = element.contributors.indexOf(contributor);
              if (index !== -1) {
                element.contributors[index] = resp;
                // Como o código vue não percebe mudanças tão profundas reinstanciamos o objeto para que seja disparada a mudança
                // element.contributors = Array.from(element.contributors);
                this.update_key();
              }
              //this.deboucedRefreshTable();
            })
            .finally(() => {
              contributor_loading.done();
            });
        });
        contributors_loading.done();

        //fetch responsible data
        let responsibles_loading = loading.addLoader("responsibles_loading");
        element.responsible.forEach((responsible) => {
          let responsible_loading = loading.addLoader("responsible_loading");
          users_service
            .get(responsible)
            .then((resp) => {
              let index = element.responsible.indexOf(responsible);
              if (index !== -1) {
                element.responsible[index] = resp;
                // Como o código vue não percebe mudanças tão profundas reinstanciamos o objeto para que seja disparada a mudança
                // element.responsible = Array.from(element.responsible);
                this.update_key();
              }
              //this.deboucedRefreshTable();
            })
            .finally(() => {
              responsible_loading.done();
            });
        });
        responsibles_loading.done();
      });
      tasks_data_loading.done();
    },
    update_key() {
      this.table_key = utils.uuidv4();
    },
    findTasks(params) {
      let tasks_service_loading = loading.addLoader("tasks_service_loading");
      this.show = false;
      loading.isDone(() => {
        this.show = true;
      });
      this.service
        .find(
          Object.assign({}, this.common_params, params),
          undefined,
          "findTasks"
        )
        .then((resp) => {
          this.$set(
            this,
            "tasksData",
            resp.map((t) => t.id)
          );
          this.processTasksData();
        })
        .finally(() => {
          tasks_service_loading.done();
        });
    },
  },
  watch: {
    tasksData: {
      handler() {
        setTimeout(() => {
          this.table_key = utils.uuidv4();
          this.$refs.table && this.$refs.table.refresh();
        }, 100);
      },
      deep: true,
    },
    "shared.activeWorkspace": {
      handler(a) {
        this.refresh();
      },
    },
    only_users() {
      this.refresh();
    },
  },
};
</script>
