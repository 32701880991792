<template>
  <b-card header="Brainstorming" ref="brainstorming">
    <b-form-group label="Novas ideias para tarefas">
      <b-table
        :items="brainstorm_table"
        :fields="fields"
        :striped="true"
        :bordered="true"
        @row-dblclicked="table_dblclick"
        ref="table"
        class="card-table"
      >
        <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.width ? field.width : '' }"
          />
        </template>

        <template v-slot:cell(area)="data">
          <div v-if="Boolean(data.item.editing) == true">
            <customTypeahead
              v-model="data.item.area"
              :objKey="'name'"
              :options="areas"
              :add="true"
              @change="data.item.changed.area = true"
            />
          </div>
          <div v-else>{{ data.value }}</div>
        </template>

        <template v-slot:cell(sector)="data">
          <div v-if="Boolean(data.item.editing) == true">
            <customTypeahead
              v-model="data.item.sector"
              :options="sectors"
              :objKey="'name'"
              :add="true"
              @change="data.item.changed.sector = true"
            />
          </div>
          <div v-else>{{ data.value }}</div>
        </template>

        <template v-slot:cell(responsible)="data">
          <div v-if="Boolean(data.item.editing) == true">
            <div
              class="mb-1 mr-1 d-inline-block"
              v-for="r in data.item.responsible"
              :key="r.id"
            >
              <b-btn
                size="sm"
                variant="primary"
                @click="
                  data.item.responsible.splice(
                    data.item.responsible.indexOf(r),
                    1
                  );
                  data.item.changed.responsible = true;
                "
              >
                {{ r.name }}
                <i class="ion ion-md-close"></i>
              </b-btn>
            </div>
            <customTypeahead
              v-model="data.item.responsible"
              :multiple="true"
              :options="users"
              track-by="id"
              :objKey="'name'"
              @change="data.item.changed.responsible = true"
            />
          </div>
          <div v-else>
            <div v-for="r in data.item.responsible" :key="r.id">
              {{ r.name }}
            </div>
          </div>
        </template>
        <template v-slot:cell(team)="data">
          <div v-if="data.item.editing">
            <div
              class="mb-1 mr-1 d-inline-block"
              v-for="r in data.item.contributors"
              :key="r.id"
            >
              <b-btn
                size="sm"
                variant="primary"
                @click="
                  data.item.contributors.splice(
                    data.item.contributors.indexOf(r),
                    1
                  );
                  data.item.changed.contributors = true;
                "
              >
                {{ r.name }}
                <i class="ion ion-md-close"></i>
              </b-btn>
            </div>
            <customTypeahead
              v-model="data.item.contributors"
              :multiple="true"
              :options="users"
              track-by="id"
              :objKey="'name'"
              @change="data.item.changed.contributors = true"
            />
          </div>
          <div v-else>
            <div v-for="r in data.item.contributors" :key="r.id">
              {{ r.name }}
            </div>
          </div>
        </template>

        <template v-slot:cell(name)="data">
          <div v-if="Boolean(data.item.editing)">
            <input
              type="text"
              class="form-control"
              v-model="data.item.name"
              @change="data.item.changed.name = true"
            />
          </div>
          <div v-else>{{ data.value }}</div>
        </template>

        <template v-slot:cell(guidelines)="data">
          <div
            v-if="Boolean(data.item.editing) == false"
            style="max-height: 70px; overflow-y: auto"
          >
            <ul v-if="data.item.guidelines.length">
              <li
                v-for="guideline in data.item.guidelines"
                :key="guideline.pk"
                :class="{ striked: guideline.checked }"
              >
                {{ guideline.guideline }}
                {{
                  `${
                    guideline.creator ? `- ${guideline.creator.first_name}` : ""
                  }`
                }}
                | {{ date(guideline.created_at) }}
              </li>
            </ul>
          </div>
          <div v-if="data.item.editing">
            <ul v-if="data.item.guidelines.length" style="list-style: none">
              <li
                v-for="guideline in data.item.guidelines"
                :key="guideline.pk"
                :class="{ striked: guideline.checked }"
              >
                <label for>
                  <input
                    type="checkbox"
                    v-model="guideline.checked"
                    @change="data.item.changed.guidelines = true"
                  />
                  {{ guideline.guideline }}
                </label>
              </li>
            </ul>
            <input
              type="text"
              class="form-control"
              v-model="data.item.new_guideline"
              @change="data.item.changed.guidelines = true"
            />
          </div>
        </template>
        <template v-slot:cell(action)="data">
          <div>
            <div v-if="data.item.editing">
              <div style="display: inline-flex">
                <b-button-group>
                  <b-btn
                    variant="default btn-xs icon-btn md-btn-flat"
                    v-b-tooltip.window="'Finalizar edição'"
                    @click="doneEditing(data.item)"
                  >
                    <i class="ion ion-md-checkmark"></i>
                  </b-btn>
                  <b-btn
                    variant="default btn-xs icon-btn link"
                    class="d-inline"
                    v-b-tooltip.window
                    title="Anotações"
                    @click="data.toggleDetails"
                    v-if="data.item.id"
                  >
                    <i class="ion ion-ios-arrow-down"></i>
                  </b-btn>
                  <b-btn
                    variant="default btn-xs icon-btn link"
                    class="d-inline"
                    v-b-tooltip.window
                    title="Cancelar"
                    @click="cancelClick(data.item)"
                  >
                    <i class="ion ion-md-close"></i>
                  </b-btn>
                </b-button-group>
              </div>
            </div>
            <div v-else>
              <b-btn
                variant="default btn-xs icon-btn link danger"
                style="color: #d9534f; margin-left: 0.2rem"
                v-b-tooltip.window
                title="Remover"
                @click="removeTask(data.item)"
                v-if="Boolean(data.item.id)"
              >
                <i class="ion ion-md-trash"></i>
              </b-btn>
            </div>
          </div>
        </template>
      </b-table>
    </b-form-group>
  </b-card>
</template>

<script>
import auth_service from "@/services/auth/auth";
import users_service from "@/services/auth/users";
import area_service from "@/services/general/areas";
import sectors_service from "@/services/general/sectors";
import loading from "@/utils/loading";

import shared from "@/utils/shared";
import utils from "@/utils/utils";
const uuidv4 = utils.uuidv4;
export default {
  metaInfo: {
    title: "Brainstorm",
  },
  components: {
    customTypeahead: () => import("@/components/Utils/CustomTypeahead.vue"),
  },
  created() {
    window.vm = this;
    let users_service_loading = loading.addLoader("users_service");
    users_service
      .get(this.logged_user.id)
      .then((resp) => {
        this.logged_user = resp;
        this.brainstorming = JSON.parse(
          resp.brainstorming ? resp.brainstorming : JSON.stringify([])
        );
      })
      .finally(() => {
        users_service_loading.done();
      });
    this.refresh();
  },
  data() {
    return {
      loading,
      logged_user: auth_service.getUserInformation(),
      sectors: [],
      areas: [],
      users: [],
      brainstorming: [],
      fields: [
        {
          key: "area",
          tdClass: "align-middle",
          label: "Área",
          formatter: (obj) => {
            if (obj) {
              return obj.name;
            }
            return "Nenhuma";
          },
          width: "100px",
        },
        {
          key: "sector",
          tdClass: "align-middle",
          label: "Area/Setor",
          formatter: (obj) => {
            if (obj) {
              return obj.name;
            }
            return "Nenhum";
          },
          width: "100px",
        },
        {
          key: "responsible",
          tdClass: "align-middle",
          label: "Respons.",
          width: "100px",
        },
        {
          key: "team",
          tdClass: "align-middle",
          label: "Time",
          width: "100px",
        },
        {
          key: "name",
          tdClass: "align-middle",
          label: "Tarefa",
        },
        {
          key: "action",
          tdClass: "align-middle",
          label: "",
          width: "10px",
        },
      ],
    };
  },
  computed: {
    brainstorm_table() {
      let brainstorm = this.brainstorming;

      if (!brainstorm) {
        brainstorm = [];
      }

      let addEmpty = true;
      brainstorm.forEach((element) => {
        if (!element.id) {
          addEmpty = false;
        }
      });
      if (addEmpty) {
        brainstorm.push({
          changed: {},
          editing: true,
          area: "",
          sector: "",
          responsible: [],
          contributors: [],
          name: "",
        });
      }

      return brainstorm;
    },
  },
  methods: {
    refresh() {
      let users_service_loading = loading.addLoader("users_service");
      users_service
        .find({ workspace: shared.activeWorkspace.id })
        .then((resp) => {
          let copy = [];
          resp.forEach((user) => {
            if (user.is_active) {
              copy.push(user);
            }
          });
          this.users = copy;
        })
        .finally(() => {
          users_service_loading.done();
        });

      let area_service_loading = loading.addLoader("area_service");
      area_service
        .find({ workspace: shared.activeWorkspace.id })
        .then((resp) => {
          this.areas = resp;
        })
        .finally(() => {
          area_service_loading.done();
        });

      let sectors_service_loading = loading.addLoader("sectors_service");
      sectors_service
        .find({ workspace: shared.activeWorkspace.id })
        .then((resp) => {
          this.sectors = resp;
        })
        .finally(() => {
          sectors_service_loading.done();
        });
      loading.isDone();
    },
    updateBrainstormTable() {
      if (this.brainstorming != null) {
        let copy = JSON.parse(JSON.stringify(this.brainstorming));

        //cleanup
        for (let index = 0; index < copy.length; index++) {
          delete copy[index].changed;
          delete copy[index].editing;
          if (!copy[index].id) {
            copy.splice(i, 1);
          }
        }

        users_service.update(
          this.logged_user.id,
          {
            username: this.logged_user.username,
            brainstorming: JSON.stringify(copy),
          },
          () => {}
        );
      }
    },
    toggleEditing(item) {
      if (item.id && item.status != 2) {
        item.editing = !item.editing;
        this.$refs.table.refresh();
      }
    },

    table_dblclick(item) {
      if (!item.editing) {
        this.toggleEditing(item);
      }
    },
    doneEditing(item) {
      if (!item.id) {
        item.id = uuidv4();
        delete item.editing;
      } else {
        for (let index = 0; index < this.brainstorming.length; index++) {
          const element = this.brainstorming[index];
          if (element.id == item.id) {
            item.editing = false;
            this.brainstorming[index] = item;
          }
        }
      }
      this.brainstorming = JSON.parse(JSON.stringify(this.brainstorming));
      this.updateBrainstormTable();
    },
    removeTask(item) {
      for (let index = 0; index < this.brainstorming.length; index++) {
        const element = this.brainstorming[index];
        if (element.id == item.id) {
          this.brainstorming.splice(index, 1);
        }
      }
      this.brainstorming = JSON.parse(JSON.stringify(this.brainstorming));
      this.updateBrainstormTable();
    },
  },
};
</script>
