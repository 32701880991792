import generic_api from "@/services/generic_api";
import config from "@/config";
import axios from "axios";

let users = new generic_api("/users");

users.upload_picture = (picture, callback) => {
  let formData = new FormData();

  formData.append("picture", picture);

  axios
    .post(`${users.url}upload_picture/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function(resp) {
      callback(resp.data);
    })
    .catch(function(error) {
      console.log("FAILURE!!");
      console.log(error);
    });
};

users.request_notification_key = (callback) => {
  axios
    .get(`${users.url}notifications_key/`)
    .then(function(resp) {
      callback(resp.data);
    })
    .catch(function(error) {
      console.log("FAILURE!!");
      console.log(error);
    });
};

users.loadServerImg = (user) => {
  return `${config.https ? "https" : "http"}://${config.api.host}:${
    config.api.port
  }${user.picture_thumb}`;
};

users.deactivate_user = (id, data, callback) => {
  axios
    .post(`${users.url}${id}/deactivate_user/`, (data = data))
    .then(function(resp) {
      callback(resp.data);
    })
    .catch(function(error) {
      console.log("FAILURE!!");
      console.log(error);
    });
};

// avoid reference copying
users.base_object = {
  id: "",
  name: "",
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  sex: "",
  picture: "",
  picture_thumb: "",
  account_type: 2,
  can_create: true,
  phone: "",
  birthday: "",
  area: [],
  sector: [],
  workspaces: [],
};

export default users;
