export default (() => {
  let instance = {
    activeWorkspace: {
      name: "Selecione um workspace",
      none_selected: true,
    },
    workspaces: [],
  };

  Object.assign(instance.activeWorkspace, {
    get selected() {
      return !Boolean(instance.activeWorkspace.none_selected);
    },
  });

  let aw = localStorage.activeWorkspace;

  if (aw) {
    aw = JSON.parse(aw);
    instance.activeWorkspace = aw;
  }

  return instance;
})();
