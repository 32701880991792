<template>
  <div class="px-5">
    <h4
      class="d-flex justify-content-between align-items-center w-100 font-weight-bold my-3"
    >
      <div>Usuários</div>
      <b-btn
        variant="primary btn-round"
        class="d-block"
        :to="{ name: 'UsersCreate' }"
        v-if="logged_user.account_type == 0"
      >
        <span class="ion ion-md-add"></span>&nbsp; Criar Usuário
      </b-btn>
    </h4>

    <!-- Filters -->
    <div class="ui-bordered mb-4">
      <div class="form-row align-items-center">
        <div class="col-md mb-4">
          <label class="form-label">Tipo de usuário</label>
          <b-select
            v-model="filterRole"
            :options="account_types"
            @change="filter"
          />
        </div>
        <div class="col-md mb-4">
          <label class="form-label">Status</label>
          <b-select
            v-model="filterStatus"
            :options="filterStatus_options"
            @change="filter"
          />
        </div>
        <div class="col-md mb-4">
          <label class="form-label">Ultimo Login</label>
          <flat-pickr
            :value="null"
            :config="picker_config"
            :placeholder="!isIEMode ? '' : null"
            @on-change="filter"
          />
        </div>
      </div>
    </div>
    <!-- / Filters -->

    <b-card>
      <!-- Table controls -->
      <b-card-body>
        <div class="row">
          <div class="col">
            Por página: &nbsp;
            <b-select
              size="sm"
              v-model="perPage"
              :options="[10, 20, 30, 40, 50]"
              class="d-inline-block w-auto"
            />
          </div>
          <div class="col">
            <b-input
              size="sm"
              placeholder="Pesquisar..."
              class="d-inline-block w-auto float-sm-right"
              @input="filter($event)"
            />
          </div>
        </div>
      </b-card-body>
      <!-- / Table controls -->

      <!-- Table -->
      <hr class="border-light m-0" />
      <div class="table-responsive">
        <b-table
          :items="usersData"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :striped="true"
          :bordered="true"
          :current-page="currentPage"
          :per-page="perPage"
          class="card-table"
        >
          <template v-slot:cell(id)="data"
            >{{ data.item.id | reduce(8) }}...</template
          >

          <template v-slot:cell(last_login)="data">{{
            data.item.last_login | date
          }}</template>

          <template v-slot:cell(name)="data">
            <router-link
              :to="{ name: 'UsersView', params: { id: data.item.id } }"
              >{{ data.item.name }}</router-link
            >
          </template>

          <template v-slot:cell(account_type)="data">
            <span>{{
              account_types.filter((e) => e.value == data.value)[0]["text"]
            }}</span>
          </template>

          <template v-slot:cell(is_active)="data">
            <b-badge variant="outline-success" v-if="data.item.is_active"
              >Ativo</b-badge
            >
            <b-badge variant="outline-default" v-else>Desativado</b-badge>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="data.item.is_active">
              <b-btn-group v-if="logged_user.account_type == 0">
                <b-btn
                  variant="default btn-xs icon-btn md-btn-flat"
                  v-b-tooltip.hover
                  title="Edit"
                  :to="{ name: 'UsersEdit', params: { id: data.item.id } }"
                >
                  <i class="ion ion-md-create"></i>
                </b-btn>
                <b-dropdown
                  variant="default btn-xs icon-btn md-btn-flat hide-arrow"
                  :right="!isRTL"
                >
                  <template v-slot:button-content>
                    <i class="ion ion-ios-settings"></i>
                  </template>
                  <b-dropdown-item
                    :to="{ name: 'UsersView', params: { id: data.item.id } }"
                    >Ver perfil</b-dropdown-item
                  >
                  <b-dropdown-item @click="deactivate_user(data.item)"
                    >Desativar usuário</b-dropdown-item
                  >
                  <b-dropdown-item @click="delete_user(data.item)"
                    >Remover Usuário</b-dropdown-item
                  >
                </b-dropdown>
              </b-btn-group>
              <b-btn
                variant="default btn-xs icon-btn md-btn-flat"
                v-b-tooltip.hover
                title="Edit"
                :to="{ name: 'UsersView', params: { id: data.item.id } }"
                v-else
              >
                <i class="ion ion-md-eye"></i>
              </b-btn>
            </div>
            <div v-else>
              <b-btn-group>
                <b-btn
                  variant="default btn-xs icon-btn md-btn-flat"
                  v-b-tooltip.hover
                  @click="deactivate_user(data.item)"
                  title="Reativar Usuário"
                >
                  <i class="ion ion-md-alert"></i>
                </b-btn>
                <b-btn
                  @click="delete_user(data.item)"
                  variant="default btn-xs icon-btn md-btn-flat"
                  v-b-tooltip.hover
                  title="Remover Usuário"
                >
                  <i class="ion ion-md-trash"></i>
                </b-btn>
              </b-btn-group>
            </div>
          </template>
        </b-table>
      </div>

      <!-- Pagination -->
      <b-card-body class="pt-0 pb-3">
        <div class="row">
          <div class="col-sm text-sm-left text-center pt-3">
            <span class="text-muted" v-if="totalItems"
              >Página {{ currentPage }} de {{ totalPages }}</span
            >
          </div>
          <div class="col-sm pt-3">
            <b-pagination
              class="justify-content-center justify-content-sm-end m-0"
              v-if="totalItems"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              size="sm"
            />
          </div>
        </div>
      </b-card-body>
      <!-- / Pagination -->
    </b-card>
  </div>
</template>
<script>
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import momentjs from "moment";
import swal from "sweetalert2";
import loading from "@/utils/loading";

import users_service from "@/services/auth/users";
import auth_service from "@/services/auth/auth";

export default {
  name: "pages-user-list",
  metaInfo: {
    title: "Usuários",
  },
  components: {
    flatPickr: () => import("vue-flatpickr-component"),
  },
  data: () => ({
    picker_config: {
      altInput: true,
      animate: true,
      dateFormat: "d/m/Y",
      altFormat: "d/m/Y",
      mode: "range",
      locale: Portuguese,
    },
    logged_user: auth_service.getUserInformation(),
    account_types: [
      {
        value: -1,
        text: "",
      },
      {
        value: 0,
        text: "Diretor",
      },
      {
        value: 1,
        text: "Gestor",
      },
      {
        value: 2,
        text: "Colaborador",
      },
    ],
    filterStatus_options: [
      {
        value: -1,
        text: "",
      },
      {
        value: 1,
        text: "Ativo",
      },
      {
        value: 0,
        text: "Desativado",
      },
    ],
    // Options
    searchKeys: ["id", "account", "email", "name"],
    sortBy: "id",
    sortDesc: false,
    perPage: 10,

    fields: [
      { key: "id", sortable: true, tdClass: "align-middle" },
      { key: "name", sortable: true, tdClass: "align-middle", label: "Nome" },
      { key: "email", sortable: true, tdClass: "align-middle" },
      {
        key: "last_login",
        sortable: true,
        tdClass: "align-middle",
        label: "Ultimo Login",
      },
      {
        key: "account_type",
        sortable: true,
        tdClass: "align-middle",
        label: "Tipo de usuário",
      },
      {
        key: "is_active",
        sortable: true,
        tdClass: "align-middle",
        label: "Status",
      },
      { key: "is_active", sortable: true, tdClass: "align-middle" },
      {
        key: "actions",
        label: "Ações",
        tdClass: "text-nowrap align-middle text-right",
      },
    ],

    // Filters
    filterRole: -1,
    filterStatus: 1,
    filterLatestActivity: null,

    usersData: [],
    originalUsersData: [],

    currentPage: 1,
  }),

  computed: {
    totalItems() {
      return this.usersData.length;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    },
  },

  methods: {
    filter(value) {
      //Como o flatpicker nao estava atualizando corretamente o model o filterLatestActivity está sendo atualizado manualmente
      if (Array.isArray(value)) {
        this.filterLatestActivity = value;
      }
      let filtered = this.originalUsersData.filter((element) => {
        let allow = true;
        if (this.filterRole != -1) {
          if (element.account_type != this.filterRole) {
            allow = false;
          }
        }

        if (this.filterStatus != -1) {
          if (element.is_active != this.filterStatus) {
            allow = false;
          }
        }

        if (this.filterLatestActivity) {
          if (element.last_login) {
            let login_date = new Date(element.last_login);
            if (this.filterLatestActivity.length == 1) {
              if (login_date < this.filterLatestActivity[0]) {
                allow = false;
              }
            } else {
              if (this.filterLatestActivity.length >= 2) {
                //converte para objetos do tipo momenjs para mover o segundo valor o mais proximo possivel do final do dia
                this.filterLatestActivity[0] = momentjs(
                  this.filterLatestActivity[0]
                );
                this.filterLatestActivity[1] = momentjs(
                  this.filterLatestActivity[1]
                )
                  .hour(23)
                  .minute(59)
                  .second(59);
                login_date = momentjs(login_date);

                if (
                  login_date < this.filterLatestActivity[0] ||
                  login_date > this.filterLatestActivity[1]
                ) {
                  allow = false;
                }
              }
            }
          } else {
            allow = false;
          }
        }

        if (value) {
          if (typeof value == "string") {
            let keys_match = this.searchKeys.filter((key) => {
              if (element.hasOwnProperty(key)) {
                if (
                  String(element[key])
                    .toLowerCase()
                    .includes(String(value).toLowerCase())
                ) {
                  return key;
                }
              }
            });
            if (keys_match.length <= 0) {
              allow = false;
            }
          }
        }

        if (allow) {
          return element;
        }
      });
      this.usersData = filtered;
    },
    users_all_callback(data) {
      this.originalUsersData = data;
      this.usersData = this.originalUsersData;
      this.filter();
    },
    deactivate_user(user) {
      swal
        .fire({
          title: "Desativar Usuário",
          text: "Tem certeza que você deseja desativar este usuário?",
          type: "question",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim",
        })
        .then((a) => {
          if (a) {
            users_service.deactivate_user(
              user.id,
              { is_active: !user.is_active },
              (resp) => {
                this.refresh();
              }
            );
          }
        });
    },
    delete_user(user) {
      if (user.is_active) {
        swal.fire({
          title: "Não é possivel deletar",
          text: "Remover um usuário não é recomendado. Se quiser apenas impedir o login deste usuario no sistema basta desativá-lo, se tiver certeza que deseja remove-lo primeiro desative-o e depois selecione remover",
          type: "warning",
        });
      } else {
        swal
          .fire({
            title: "Remover Usuário",
            text: "Tem certeza que você deseja REMOVER este usuário?",
            type: "error",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sim",
          })
          .then((a) => {
            if (a) {
              users_service.remove(user.id, (resp) => {
                this.refresh();
              });
            }
          });
      }
    },
    refresh() {
      let users_service_loading = loading.addLoader("users_service");
      users_service
        .all()
        .then(this.users_all_callback)
        .finally(() => {
          users_service_loading.done();
        });
      loading.isDone();
    },
  },

  created() {
    this.refresh();
  },
  filters: {
    reduce(obj, size) {
      return obj.slice(0, size);
    },
    date(obj) {
      try {
        if (!obj) {
          return "Nunca fez login";
        }
        let date = new Date(obj);
        return date.toLocaleDateString();
      } catch (error) {}
    },
  },
};
</script>
