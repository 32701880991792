import { render, staticRenderFns } from "./UsersEdit.vue?vue&type=template&id=0665490f"
import script from "./UsersEdit.vue?vue&type=script&lang=js"
export * from "./UsersEdit.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@/vendor/libs/vue-multiselect/vue-multiselect.scss?vue&type=style&index=1&prod&lang=scss&external"
import style2 from "@/vendor/styles/pages/users.scss?vue&type=style&index=2&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports