
import Layout2 from '@/layout/Layout2'
import Report from '@/pages/Report/Report'

export default {
    component: Layout2,
    path: '/Report',
    children: [
        {
            path: '',
            name: 'Report',
            component: Report
        }
    ]
}