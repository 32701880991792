import Vue from "vue";
import Router from "vue-router";

import Layout2 from "@/layout/Layout2";

import tasks from "@/router/routes/tasks";
import auth from "@/router/routes/auth";
import users from "@/router/routes/users";
import dashboard from "@/router/routes/dashboard";
import chat from "@/router/routes/chat";
import workspace from "@/router/routes/workspace";
import config from "@/router/routes/config";
import license from "@/router/routes/license";
import audit from "@/router/routes/audit";
import report from "@/router/routes/report";
import routine from "@/router/routes/routine";
import routinenav from "@/router/routes/routinenav";
import layout_helpers from "@/layout/helpers";
import auth_service from "@/services/auth/auth";

Vue.use(Router);

let router = new Router({
  routes: [auth, users, dashboard, tasks, chat, workspace, config, license, audit, report, routine, routinenav],
  linkExactActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  $EventBus.$emit("toggleLoading", true, "router.beforeeach");
  if (auth_service.isAuthenticated == false && to.name != "Login") {
    next({
      name: "Login",
    });
  } else {
    // Percorre os components daquela rota para carregar o titulo da pagina
    to.matched.forEach((element) => {
      let metaInfo = element.components.default.metaInfo || (element.components.default.options && element.components.default.options.metaInfo)
      if (metaInfo) {
        document.title = metaInfo.title;
      }
    });
    next();
  }
});

router.afterEach(() => {
  layout_helpers.setCollapsed(true);
});

export default router;
