<template>
  <div>
    <h3 class="font-weight-bold py-3 mb-4">Auditoria</h3>

    <b-row class="my-2 p-3">
      <b-col cols="3">
        <h5>Filtrar por data</h5>

        <flat-pickr
          @on-change="filterpicker_model"
          @on-close="
            (selectedDates, dateStr, instance) => {
              if (selectedDates.length == 1) {
                instance.setDate([selectedDates[0], selectedDates[0]], true);
              }
            }
          "
          ref="filterpicker"
          v-model="filterpicker_model_i"
          :config="filterpicker_config"
        />
      </b-col>
      <b-col cols="3">
        <h5>Pesquisar</h5>
        <b-input-group>
          <b-input-group-text slot="prepend">
            <i class="fa fa-search"></i>
          </b-input-group-text>
          <b-input
            v-model="filters.search"
            debounce="250"
            :disabled="!loading.done"
          />
        </b-input-group>
      </b-col>
    </b-row>

    <div v-for="log in logs" :key="log.pk">
      <b-card class="mb-3">
        <div class="media align-items-center">
          <div class="media-body ml-4">
            <span class="text-big"></span>
            <div class="my-2">
              {{
                log.actor
                  ? `${log.actor.first_name} ${log.actor.last_name}`
                  : "O sistema"
              }}
              {{ actions[log.action] }}
              {{
                models[log.content_type]
                  ? models[log.content_type]
                  : log.content_type
              }}
              com os seguintes dados:
              <div class="py-2">
                <ul>
                  <li
                    v-for="change_key in Object.keys(log.changes)"
                    :key="change_key"
                  >
                    {{ fields[change_key] ? fields[change_key] : change_key }}:
                    {{
                      log.action == 0 ||
                      log.changes[change_key][0].indexOf("None") != -1
                        ? special_field_types[change_key]
                          ? special_field_types[change_key](
                              log.changes[change_key][1]
                            )
                          : log.changes[change_key][1]
                        : `Anterior: ${
                            special_field_types[change_key]
                              ? special_field_types[change_key](
                                  log.changes[change_key][0]
                                )
                              : log.changes[change_key][0]
                          }; Novo:${
                            special_field_types[change_key]
                              ? special_field_types[change_key](
                                  log.changes[change_key][1]
                                )
                              : log.changes[change_key][1]
                          }`
                    }}
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div class="my-2">{{log}}</div> -->
            <div class="small">
              <span class="text-muted ml-3">
                <i
                  class="ion ion-md-time text-lighter text-big align-middle"
                ></i>
                &nbsp; Registrado em: {{ formatDate(log.timestamp) }}
              </span>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import loading from "@/utils/loading";
import { to_locale_date } from "@/utils/utils";
import audit_service from "@/services/audit/audit";
import users_service from "@/services/auth/users";
import * as $ from "jquery";
export default {
  name: "PAudit",
  components: { flatPickr: () => import("vue-flatpickr-component") },
  metaInfo: {
    title: "Auditoria",
  },
  data: () => ({
    next: undefined,
    loading,
    logs: [],
    actions: {
      0: "Criou",
      1: "Atualizou",
      2: "Removeu",
    },
    models: {
      taskguideline: "uma diretriz",
      task: "uma tarefa",
    },
    fields: {
      creator: "Criador",
      guideline: "Açõ",
      task: "Tarefa",
      checked: "Resolvida",
      id: "Identificador no banco de dados",
      created_at: "Criado em",
      updated_at: "Atualizado em",
      area: "Área",
      sector: "Area/Setor",
      spent_hours: "Horas trabalhadas",
      workspace: "Espaço de trabalho",
      subtasks: "SubTarefas",
      guidelines: "Ações",
      discussion: "Comentários",
      extended_expected_finish_date: "Adiamentos",
      annotations: "Anotações",
      attachments: "Anexos",
      human_id: "Id",
      name: "Nome",
      expected_finish_date: "Data limite",
      priority: "Prioridade",
      status: "Status",
      start_date: "Data de inicio",
      finish_date: "Data final",
    },
    special_field_types: {
      created_at: (date) => {
        return this.a.methods.formatDate(new Date(date));
      },
      updated_at: (date) => {
        return this.a.methods.formatDate(new Date(date));
      },
      checked: (done) => (done == "True" ? "Sim" : "Não"),
      subtasks: (txt) => (txt.indexOf(".None") != -1 ? "Nenhuma" : txt),
      guidelines: (txt) => (txt.indexOf(".None") != -1 ? "Nenhuma" : txt),
      discussion: (txt) => (txt.indexOf(".None") != -1 ? "Nenhum" : txt),
      annotations: (txt) => (txt.indexOf(".None") != -1 ? "Nenhuma" : txt),
      attachments: (txt) => (txt.indexOf(".None") != -1 ? "Nenhuma" : txt),
      extended_expected_finish_date: (txt) =>
        txt.indexOf(".None") != -1 ? "Nenhum" : txt,
    },
    Object: Object,

    filterpicker_model_i: "",
    filterpicker_config: {
      altInput: true,
      animate: true,
      dateFormat: "d/m/Y",
      altFormat: "d/m/Y",
      mode: "range",
      locale: Portuguese,
    },
    filters: {
      search: "",
      timestamp__gte: "",
      timestamp__lte: "",
    },
  }),
  created() {
    this.refresh();
    $(window).off("scroll").on("scroll", _.debounce(this.handle_scroll, 100));
    this.deboucedRefresh = _.debounce(this.refresh, 100);
  },
  methods: {
    refresh() {
      let audit_service_loading = loading.addLoader("audit_service");
      let filters = {};
      for (let index = 0; index < Object.keys(this.filters).length; index++) {
        const element = Object.keys(this.filters)[index];
        if (this.filters[element]) {
          filters[element] = this.filters[element];
        }
      }
      audit_service
        .find(Object.assign({ limit: 10 }, filters), undefined, undefined, true)
        .then((resp) => {
          this.logs.unshift(resp.results);
          // console.log(this.logs)
          this.next = resp.next ? new URL(resp.next) : undefined;
          //load actors
          let actors = [];

          resp.results.forEach((log) => {
            actors.push(log.actor);
            log.changes = JSON.parse(log.changes ? log.changes : "{}");
          });

          users_service.prefetch(actors).then(() => {
            resp.results.forEach((log) => {
              if (log.actor) {
                users_service.get(log.actor).then((actor) => {
                  this.$set(log, "actor", actor);
                });
              }
            });
          });
        })
        .catch((error) => {
          // console.log(error);
        })
        .finally(() => {
          audit_service_loading.done();
        });
      loading.isDone(() => {
        this.$forceUpdate();
      });
    },
    formatDate(date) {
      if (date.getTime) {
        date = date.getTime() / 1000;
      }
      if (!isNaN(date)) {
        date = Number(date) * 1000;
      }
      date = new Date(date);
      return to_locale_date(date);
    },
    handle_scroll(event) {
      if (
        $(window).scrollTop() + $(window).height() >
        $(document).height() - 100
      ) {
        if (loading.done) {
          this.getNext();
        }
      }
    },
    getNext() {
      // console.log(this, this.next);
      if (this.next) {
        let params = {};
        Array.from(this.next.searchParams).forEach((e) => {
          params[e[0]] = e[1];
        });
        audit_service
          .find(params, undefined, undefined, true)
          .then((resp) => {
            this.logs = this.logs.concat(resp.results);
            // console.log(resp.results);
            this.next = resp.next ? new URL(resp.next) : undefined;
            //load actors
            resp.results.forEach((log) => {
              if (log.actor) {
                users_service.get(log.actor).then((actor) => {
                  this.$set(log, "actor", actor);
                });
              }

              log.changes = JSON.parse(log.changes ? log.changes : "{}");
            });
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    },
    filterpicker_model(selectedDates) {
      if (selectedDates.length > 1) {
        let d1 = selectedDates[0].toISOString();
        let d2 = selectedDates[1].toISOString();

        this.$set(this.filters, "timestamp__gte", d1);
        this.$set(this.filters, "timestamp__lte", d2);
      }
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.deboucedRefresh();
      },
    },
  },
};
</script>
