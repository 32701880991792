<template>
  <div class="px-5">
    <h4
      class="d-flex justify-content-between align-items-center w-100 font-weight-bold my-3"
    >
      <div>Workspaces</div>
    </h4>

    <b-card>
      <!-- Table controls -->
      <b-card-body>
        <div class="row">
          <div class="col">
            Por página: &nbsp;
            <b-select
              size="sm"
              v-model="perPage"
              :options="[10, 20, 30, 40, 50]"
              class="d-inline-block w-auto"
            />
          </div>
          <div class="col">
            <b-input
              size="sm"
              placeholder="Pesquisar..."
              class="d-inline-block w-auto float-sm-right"
              v-model.lazy="search_term"
            />
          </div>
        </div>
      </b-card-body>
      <!-- / Table controls -->

      <!-- Table -->
      <hr class="border-light m-0" />
      <div class="table-responsive">
        <b-table
          :items="sorted_wokspaces"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :striped="true"
          :bordered="true"
          :current-page="currentPage"
          :per-page="perPage"
          @row-dblclicked="table_dblclick"
          class="card-table"
          ref="table"
          :no-local-sorting="true"
          :key="table_key"
        >
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width ? field.width : '' }"
            />
          </template>
          <template v-slot:cell(id)="data"
            >{{ data.item.id | reduce(8) }}...</template
          >

          <template v-slot:cell(name)="data">
            <div v-if="data.item.editing">
              <input type="text" v-model="data.item.name" />
            </div>
            <div v-else>{{ data.value }}</div>
          </template>

          <template v-slot:cell(default)="data">
            <div v-if="data.item.editing">
              <input
                type="checkbox"
                v-model="data.item.default"
                @change="verify_default(data.item)"
              />
            </div>
            <div v-else>
              <input
                type="checkbox"
                v-model="data.item.default"
                :disabled="true"
              />
            </div>
          </template>

          <template v-slot:cell(actions)="data">
            <div v-if="data.item.editing">
              <b-btn-group v-if="logged_user.account_type == 0">
                <b-btn
                  variant="default btn-xs icon-btn md-btn-flat"
                  v-b-tooltip.window="'Finalizar edição'"
                  @click="doneEditing(data.item)"
                >
                  <i class="ion ion-md-checkmark"></i>
                </b-btn>
              </b-btn-group>
              <b-btn
                variant="default btn-xs icon-btn md-btn-flat"
                v-b-tooltip.hover
                title="Edit"
                :to="{ name: 'UsersView', params: { id: data.item.id } }"
                v-else
              >
                <i class="ion ion-md-eye"></i>
              </b-btn>
            </div>
            <div v-else>
              <b-btn-group>
                <b-btn
                  variant="default btn-xs icon-btn md-btn-flat"
                  v-b-tooltip.hover
                  @click="toggleEditing(data.item)"
                  title="Reativar Workspace"
                >
                  <i class="ion ion-md-create"></i>
                </b-btn>
                <b-btn
                  @click="deleteWorkspace(data.item)"
                  variant="default btn-xs icon-btn md-btn-flat"
                  v-b-tooltip.hover
                  title="Remover Workspace"
                >
                  <i class="ion ion-md-trash"></i>
                </b-btn>
              </b-btn-group>
            </div>
          </template>
        </b-table>
      </div>

      <!-- Pagination -->
      <b-card-body class="pt-0 pb-3">
        <div class="row">
          <div class="col-sm text-sm-left text-center pt-3">
            <span class="text-muted" v-if="totalItems"
              >Página {{ currentPage }} de {{ totalPages }}</span
            >
          </div>
          <div class="col-sm pt-3">
            <b-pagination
              class="justify-content-center justify-content-sm-end m-0"
              v-if="totalItems"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              size="sm"
            />
          </div>
        </div>
      </b-card-body>
      <!-- / Pagination -->
    </b-card>
  </div>
</template>
<script>
import swal from "sweetalert2";
import loading from "@/utils/loading";
import * as _ from "lodash";

import workspace_service from "@/services/general/workspace";
import auth_service from "@/services/auth/auth";
import utils from "@/utils/utils";

import shared from "@/utils/shared";
export default {
  name: "PWorkspaceList",
  metaInfo: {
    title: "Workspaces",
  },
  data: () => ({
    logged_user: auth_service.getUserInformation(),
    // Options
    searchKeys: ["name"],
    sortBy: "name",
    sortDesc: false,
    perPage: 10,
    shared,

    fields: [
      { key: "id", sortable: false, tdClass: "align-middle", width: "50px" },
      {
        key: "name",
        label: "Nome do workspace",
        sortable: true,
        tdClass: "align-middle",
      },
      {
        key: "default",
        label: "Padrão",
        tdClass: "text-nowrap align-middle text-right",
        width: "10px",
      },
      {
        key: "actions",
        label: "Ações",
        tdClass: "text-nowrap align-middle text-right",
        width: "10px",
      },
    ],

    workspacesData: [],
    currentPage: 1,
    search_term: "",
    table_key: utils.uuidv4(),
  }),

  computed: {
    totalItems() {
      return this.workspacesData.length;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    },
    sorted_wokspaces() {
      // console.log("sorted_wokspaces");
      let search = this.search_term;
      let workspaces = this.workspacesData;
      let sorted = workspaces.filter((element) =>
        element.name.includes(search)
      );

      let blank = {
        name: "",
        editing: true,
      };

      return [].concat(sorted, [blank]);
    },
  },

  methods: {
    refresh() {
      let workspaces_service_loading = loading.addLoader("workspace_service");

      workspace_service.all().then((resp) => {
        this.workspacesData = resp;

        workspaces_service_loading.done();
      });

      loading.isDone();
    },
    table_dblclick(item) {
      if (!item.editing) {
        this.toggleEditing(item);
      }
    },
    date: (d) =>
      d ? (d._d ? utils.to_locale_date(d._d) : utils.to_locale_date(d)) : "",

    toggleEditing(item) {
      if (item.id && item.status != 2) {
        item.editing = !item.editing;
        this.deboucedRefreshTable();
      }
    },
    doneEditing(item) {
      //   item.editing = false;
      let p = [];
      if (!item.id) {
        p.push(
          workspace_service.add(item).then((resp) => {
            item.editing = undefined;
            Object.assign(item, resp);
          })
        );
      } else {
        p.push(
          workspace_service.update(item.id, item).then((resp) => {
            item.editing = undefined;
            Object.assign(item, resp);
          })
        );
      }
      Promise.all(p).finally(() => {
        let copy = [].concat(this.sorted_wokspaces.filter((e) => !e.editing));
        this.$set(this, "workspacesData", copy);
        this.$set(shared, "workspaces", copy);
        this.deboucedRefreshTable();
      });
    },
    deleteWorkspace(item) {
      swal
        .fire({
          title: "Tem certeza?",
          text: "Tem certeza que deseja remover esse workspace? TODAS AS TAREFAS RELACIONADAS A ESSE WORKSPACE SERÃO REMOVIDAS",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then((result) => {
          if (result.value) {
            swal
              .fire({
                title: "Tem certeza?",
                text: "Tem certeza que deseja remover esse workspace? TODAS AS TAREFAS RELACIONADAS A ESSE WORKSPACE SERÃO REMOVIDAS",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Sim",
                cancelButtonText: "Não",
              })
              .then((result) => {
                if (result.value) {
                  workspace_service.remove(item.id, () => {
                    //Will call this.refresh after parent ends update
                    this.refresh();
                  });
                }
              });
          }
        });
    },
    verify_default(item) {
      // console.log("verify_default");
      if (item.default) {
        let alerted = false;
        this.sorted_wokspaces.forEach((element) => {
          if (element != item && element.default) {
            if (!alerted) {
              swal.fire(
                "Cuidado!",
                "Apenas um workspace pode ser o padrão",
                "warning"
              );
            }
            alerted = true;
            element.default = false;
          }
        });
      } else {
        let has_default = false;
        this.sorted_wokspaces.forEach((element) => {
          if (element.default) {
            has_default = true;
          }
        });
        if (!has_default) {
          swal.fire(
            "Cuidado!",
            "Pelo menos um workspace precisa ser o padrão",
            "warning"
          );
          this.$set(item, "default", true);

          this.deboucedRefreshTable();
        }
      }
    },
  },
  created() {
    this.deboucedRefreshTable = _.debounce(() => {
      this.$refs.table && this.$refs.table.refresh();
      this.$set(this, "table_key", utils.uuidv4());
    }, 50);
    this.refresh();
  },
  filters: {
    reduce(obj, size) {
      if (!obj) {
        return obj;
      }
      return obj.slice(0, size);
    },
    date(obj) {
      try {
        if (!obj) {
          return "Nunca fez login";
        }
        let date = new Date(obj);
        return date.toLocaleDateString();
      } catch (error) {}
    },
  },
};
</script>
