var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"authentication-wrapper authentication-3"},[_c('div',{staticClass:"authentication-inner"},[_c('div',{staticClass:"d-none d-lg-flex col-lg-8 align-items-center ui-bg-cover ui-bg-overlay-container p-5",style:({
					'background-image':
						'url(' + require('@/assets/img/bg/bg.jpg') + ')',
				})},[_c('div',{staticClass:"ui-bg-overlay bg-dark opacity-50"}),_vm._m(0)]),_c('div',{staticClass:"d-flex col-lg-4 align-items-center bg-white p-5"},[_c('div',{staticClass:"d-flex co\\l-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto"},[_c('div',{staticClass:"w-100"},[_vm._m(1),_c('h4',{staticClass:"text-center text-lighter font-weight-normal mt-5 mb-0"},[_vm._v(" Entre na sua conta ")]),_c('form',{staticClass:"my-5"},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('b-input',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.doAuth.apply(null, arguments)}},model:{value:(_vm.credentials.email),callback:function ($$v) {_vm.$set(_vm.credentials, "email", $$v)},expression:"credentials.email"}})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between align-items-end",attrs:{"slot":"label"},slot:"label"},[_c('div',[_vm._v("Password")])]),_c('b-input',{attrs:{"type":"password"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.doAuth.apply(null, arguments)}},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}})],1),_c('div',{staticClass:"d-flex justify-content-between align-items-center m-0"},[_c('b-btn',{attrs:{"variant":"primary"},on:{"click":_vm.doAuth}},[_vm._v("Entrar")])],1)],1),_vm._m(2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 text-white px-5"},[_c('h1',{staticClass:"display-2 font-weight-bolder mb-4"},[_vm._v(" Gerenciador de tarefas "),_c('br'),_vm._v("Maq-Larem ")]),_c('div',{staticClass:"text-large font-weight-light"},[_vm._v(" Tenha um dia produtivo! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"ui-w-60"},[_c('div',{staticClass:"w-100 position-relative",staticStyle:{"padding-bottom":"100%"}},[_c('img',{staticClass:"w-100 h-100 position-absolute",attrs:{"src":require("@/assets/img/logo.png"),"height":"50px"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-table ml-auto"},[_c('small',[_vm._v("ver 1.9.1")])])])
}]

export { render, staticRenderFns }