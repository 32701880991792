<template>
  <CcMyTasksTable></CcMyTasksTable>
</template>

<script>
export default {
  name: "pages-my-routine-tasks-nav",
  metaInfo: {
    title: "Minhas Rotinas",
  },
  components: {
    CcMyTasksTable: () => import("@/components/Routine/TasksTable"),
  },
  data: () => {
    return {};
  },
  computed: {},

  methods: {},
  created() {
    setTimeout(() => {
      layoutHelpers.setCollapsed(true);
    }, 100);
  },
};
</script>
