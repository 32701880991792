<template>
  <CcMyTasksTable></CcMyTasksTable>
</template>

<script>
export default {
  name: "pages-my-routine-tasks",
  metaInfo: {
    title: "Criação de Rotinas",
  },
  components: {
    CcMyTasksTable: () => import("@/components/RoutineTasks/TasksTable"),
  },
  data: () => {
    return {};
  },
  computed: {},

  methods: {},
  created() {
    setTimeout(() => {
      layoutHelpers.setCollapsed(true);
    }, 100);
  },
};
</script>
