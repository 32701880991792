
import Layout2 from '@/layout/Layout2'
import RoutineTasks from '@/pages/Tasks/RoutineTasks';

export default {
    // Layout 2
    path: '/',
    component: Layout2,
    children: [
        {
            name: "RoutineTasks",
            path: "create-routine-tasks",
            component: RoutineTasks
        }
    ]
}