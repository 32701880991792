<template>
	<nav class="layout-footer footer" :class="getLayoutFooterBg()">
		<div
			class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3"
		>
			<div class="pt-3">
				<!-- <span class="footer-text font-weight-bolder">Appwork</span> © -->
				<span class="footer-text font-weight-bolder"
					>GESTOR DE TAREFAS - ver. 1.9.1</span
				>
			</div>
		</div>

		<!-- Divider -->
		<!-- <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25">|</div>
    <b-nav-text class="mr-1" v-if="$route.name == 'MyTasks'">

    </b-nav-text>-->
	</nav>
</template>

<script>
export default {
	name: "app-layout-footer",

	methods: {
		getLayoutFooterBg() {
			return `bg-${this.layoutFooterBg}`;
		},
	},
};
</script>
