<template>
  <b-card header="Direcionamento Estratégico" class="mb-4" ref="notepad">
    <b-form-group>
      <b-textarea @change="deboucedTextAreaChange" v-model="logged_user.directing" style="height:40vh" disabled></b-textarea>
    </b-form-group>
  </b-card>
</template>

<script>
import users_service from "@/services/auth/users";
import loading from "@/utils/loading";
import auth_service from "@/services/auth/auth";
export default {
  metaInfo: {
    title: "Direcionamento Estratégico"
  },
  created() {
    window.vm = this;
    let users_service_loading = loading.addLoader("users_service");
    users_service
      .get(this.logged_user.id)
      .then(resp => {
        this.logged_user = resp;
      })
      .finally(() => {
        users_service_loading.done();
      });
    loading.isDone();
    this.deboucedTextAreaChange = _.debounce(this._textAreaChange, 500);
  },
  data() {
    return {
      logged_user: auth_service.getUserInformation(),
      notes_helper: "Salvo."
    };
  },
  methods: {
    _textAreaChange() {
      if (this.logged_user.notes != null) {
        users_service.update(
          this.logged_user.id,
          {
            username: this.logged_user.username,
            notes: this.logged_user.notes
          },
          () => {
            this.notes_helper = "Salvo.";
          }
        );
      }
    }
  },
  watch: {
    "logged_user.notes": {
      handler(a) {
        this.notes_helper = "Salvando alterações...";

        this.deboucedTextAreaChange();
      }
    }
  }
};
</script>
