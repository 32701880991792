import utils from "./utils";
const { uuidv4 } = utils

export default (() => {
    let instance = {
        objects: {}
    }

    instance.addLoader = (caller) => {
        let uuid = uuidv4()
        while (instance.objects[uuid]) {
            uuid = uuidv4()
        }
        instance.objects[uuid] = {
            id: uuid,
            caller,
            loading: true,
            done() {
                this.loading = false;
            }
        }
        return instance.objects[uuid]
    }

    Object.defineProperty(instance, "done", {
        get: function () {
            for (const key in this.objects) {
                if (this.objects.hasOwnProperty(key)) {
                    const element = this.objects[key];
                    if (!element.loading) {
                        delete this.objects[key]
                    }
                    if (element.loading) {
                        let progress = Object.entries(this.objects).filter(a => a[1].loading == false).length / Object.entries(this.objects).length
                        $EventBus.$emit("toggleLoading", true, "loading util", progress);
                        return false;
                    }
                }
            }
            $EventBus.$emit("toggleLoading", false, "loading util");
            return true;
        }
    })

    instance.isDone = (callback) => {
        setTimeout(() => {
            if (!instance.done) {
                instance.isDone(callback);
            } else {
                if (callback) {
                    callback()
                }
            }
        }, 100);
    }

    instance.getNotDone = () => {
        for (const key in instance.objects) {
            if (instance.objects.hasOwnProperty(key)) {
                const element = instance.objects[key];
                if (element.loading) {
                    return element
                }
            }
        }
    }

    if(window.loading != instance){
        window.loading = instance   
    }
    return instance
})()