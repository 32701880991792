import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import vuetify from './pages/Keep/plugins/vuetify';
import store from './pages/Keep/store';
import BootstrapVue from "bootstrap-vue";
import VueNativeSock from "vue-native-websocket";
import Notifications from "vue-notification";
import VueTheMask from "vue-the-mask";
import Vuelidate from "vuelidate";
import Toasted from "vue-toasted";

import globals from "./globals";
import config from "@/config";

import Popper from "popper.js";

declare global {
    interface Window {
        _evaluate?: any;
    }
  }

window._evaluate = (params) => {
  return JSON.parse(JSON.stringify(params))
}
// Required to enable animations on dropdowns/tooltips/popovers
// Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(
  VueNativeSock,
  `${config.https ? "wss" : "ws"}://${config.websocket.host}:${config.websocket.port
  }/ws`,
  {
    // connectManually: true,
    reconnection: true, // (Boolean) whether to reconnect automatically (false)
    reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
    reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
    format: "json",
  }
);
Vue.use(Notifications);
Vue.use(Toasted);

Vue.use(VueTheMask);
Vue.use(Vuelidate);
// Global RTL flag
Vue.mixin({
  data: globals,
});

import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
const vuetify= new Vuetify();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
