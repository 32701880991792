<template>
  <!-- `.chat-wrapper` fills all available space of container -->
  <div
    class="chat-wrapper default-style h-100"
    :class="{ 'chat-sidebox-open': sideboxOpen }"
  >
    <!-- Make card full height of `.chat-wrapper` -->
    <b-card no-body class="flex-grow-1 position-relative overflow-hidden">
      <!-- Make row full height of `.card` -->
      <div class="row no-gutters h-100">
        <div class="chat-sidebox col">
          <!-- Chat contacts header -->
          <div class="flex-grow-0 px-4">
            <div class="media align-items-center">
              <div class="media-body">
                <b-input
                  class="chat-search my-3 d-inline"
                  style="width: 90%"
                  placeholder="Pesquisar..."
                  @change="debouncedSearch"
                  @input="debouncedSearch"
                />
                <a
                  href="#"
                  v-b-modal.modals-default
                  v-b-tooltip.hover="'Novo Grupo'"
                  style="color: black"
                >
                  <i
                    v-b-modal.modals-default
                    class="ion ion-md-add-circle-outline d-inline-block"
                  ></i>
                </a>
              </div>
              <a
                @click.prevent="sideboxOpen = !sideboxOpen"
                href="#"
                class="chat-sidebox-toggler d-lg-none d-block text-muted text-large font-weight-light pl-3"
                >&times;</a
              >
            </div>
            <hr class="border-light m-0" />
          </div>
          <!-- / Chat contacts header -->

          <!-- Wrap `.chat-scroll` to properly position scroll area. Remove this wtapper if you don't need scroll -->
          <div class="flex-grow-1 position-relative">
            <perfect-scrollbar
              :options="{ suppressScrollX: true, wheelPropagation: true }"
              class="chat-contacts list-group chat-scroll py-3"
              :key="chatsRefresh"
            >
              <a
                v-for="chat in chatsData"
                :key="chat.id"
                :class="{
                  online: chat.online,
                  active: chatData && chatData.id === chat.id,
                }"
                href="javascript:void(0)"
                class="list-group-item list-group-item-action"
                @click="selectChat(chat)"
              >
                <div
                  class="rounded-circle"
                  style="
                    position: relative;
                    background-color: lightgray;
                    min-height: 40px;
                    min-width: 40px;
                  "
                  v-if="!chat.avatar"
                >
                  <div
                    style="
                      margin: 0;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    "
                  >
                    <i class="fas fa-user" v-if="!chat.group"></i>
                    <i v-if="chat.group" class="fas fa-user-friends"></i>
                  </div>
                </div>
                <img
                  :src="chat.avatar"
                  class="d-block ui-w-40 rounded-circle"
                  v-if="!chat.group && chat.avatar"
                />

                <div class="media-body ml-3">
                  {{ chat.name }}
                  <div
                    v-if="
                      chat.last_message &&
                      !chat.last_message.user_read.includes(logged_user.id)
                    "
                    class="badge badge-outline-success"
                  >
                    Nova(s) mensagem(ns)
                  </div>
                  <!-- <div v-if="!chat.group">
                    <div v-if="chat.online" class="chat-status small">
                      <span class="badge badge-dot"></span>&nbsp; Online
                    </div>
                    <div v-if="!chat.online" class="chat-status small">
                      <span class="badge badge-dot"></span>&nbsp; Offline
                    </div>
                  </div>-->
                  <div>
                    <small class="text-muted" v-if="chat.last_message"
                      >{{
                        chat.last_message.creator.id == logged_user.id
                          ? "Você"
                          : chat.last_message.creator.first_name
                      }}:{{ chat.last_message.message }}</small
                    >
                  </div>
                </div>
              </a>
            </perfect-scrollbar>
            <!-- / .chat-contacts -->
          </div>
        </div>
        <div
          v-if="!chatData"
          class="d-flex col justify-content-center align-items-center"
        >
          <div class="text-lighter text-large">Selecione uma conversa</div>
        </div>
        <div v-else class="d-flex col flex-column">
          <!-- Chat header -->
          <div class="flex-grow-0 py-3 pr-4 pl-lg-4">
            <div class="media align-items-center">
              <a
                @click.prevent="sideboxOpen = !sideboxOpen"
                href="#"
                class="chat-sidebox-toggler d-lg-none d-block text-muted text-large px-4 mr-2"
              >
                <i class="ion ion-md-more"></i>
              </a>

              <div class="position-relative">
                <span
                  class="badge badge-dot indicator"
                  :class="`badge-${
                    chatData.user.online ? 'success' : 'danger'
                  }`"
                ></span>
                <i v-if="chatData.user.group" class="fas fa-user-friends"></i>
                <img
                  :src="chatData.user.avatar"
                  class="d-block ui-w-40 rounded-circle"
                  v-if="!chatData.user.group && chatData.user.avatar"
                />
                <i
                  v-if="!chatData.user.group && !chatData.user.avatar"
                  class="fas fa-user"
                ></i>
              </div>
              <div class="media-body pl-3">
                <strong>{{ chatData.user.name }}</strong>
                <div class="text-muted small">
                  <em v-if="!chatData.user.group">{{ chatData.status }}</em>
                  <em v-else>{{
                    chatData.users.map((e) => e.first_name).join(",")
                  }}</em>
                </div>
              </div>
              <div>
                <!-- <b-btn variant="primary btn-round icon-btn mr-1">
                  <i class="ion ion-ios-call"></i>
                </b-btn>
                <b-btn variant="secondary btn-round icon-btn mr-1">
                  <i class="ion ion-md-videocam"></i>
                </b-btn>-->
                <b-btn variant="default btn-round icon-btn">
                  <i class="ion ion-ios-more"></i>
                </b-btn>
              </div>
            </div>
          </div>
          <hr class="flex-grow-0 border-light m-0" />
          <!-- / Chat header -->

          <!-- Wrap `.chat-scroll` to properly position scroll area. Remove this wtapper if you don't need scroll -->
          <div class="flex-grow-1 position-relative">
            <!-- Remove `.chat-scroll` and add `.flex-grow-1` if you don't need scroll -->
            <perfect-scrollbar
              :options="{ suppressScrollX: true, wheelPropagation: true }"
              class="chat-messages chat-scroll p-4"
              ref="chat"
              :key="chatData.id"
            >
              <div
                v-for="message in chatData.messages"
                :key="message.id"
                :class="`chat-message-${
                  message.creator.id == logged_user.id ? 'right' : 'left'
                }`"
                class="mb-4"
              >
                <div>
                  <img
                    :src="`${
                      message.creator.id == logged_user.id
                        ? logged_user.picture_thumb
                        : message.creator.picture_thumb
                    }`"
                    class="ui-w-40 rounded-circle"
                    v-if="
                      message.creator.id == logged_user.id
                        ? logged_user.picture_thumb
                        : message.creator.picture_thumb
                    "
                  />
                  <i v-else class="fas fa-user"></i>
                  <div class="text-muted small text-nowrap mt-2">
                    {{ message.date }}
                  </div>
                </div>
                <div
                  class="flex-shrink-1 bg-lighter rounded py-2 px-3"
                  :class="
                    message.creator.id == logged_user.id ? 'mr-3' : 'ml-3'
                  "
                >
                  <div class="font-weight-semibold mb-1">
                    {{
                      message.creator.id == logged_user.id
                        ? "Você"
                        : message.creator.first_name
                    }}
                  </div>
                  {{ message.message }}
                </div>
              </div>
            </perfect-scrollbar>
            <!-- / .chat-messages -->
          </div>

          <!-- Chat footer -->
          <hr class="border-light m-0" />
          <div class="flex-grow-0 py-3 px-4">
            <b-input-group>
              <b-input
                placeholder="Digite a sua mensagem"
                v-model="new_message"
                @keyup.enter="send_message"
              />
              <b-btn variant="primary" slot="append" @click="send_message"
                >Enviar</b-btn
              >
            </b-input-group>
          </div>
          <!-- / Chat footer -->
        </div>
      </div>
      <!-- / .row -->
    </b-card>
    <!-- / .card -->

    <!-- Modal template -->
    <b-modal
      id="modals-default"
      ok-title="Salvar"
      cancel-title="Cancelar"
      @ok="addGroup"
    >
      <div slot="modal-title">
        Novo
        <span class="font-weight-light">Grupo</span>
        <br />
        <small class="text-muted"
          >Selecione os usuarios para o novo grupo.</small
        >
      </div>

      <b-form-row>
        <b-form-group label="Grupo" class="col">
          <b-input placeholder="Nome do grupo" v-model="new_group.name" />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="Integrantes" style="width: 100%">
          <multiselect
            v-model="new_group.to"
            :options="usersData"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            :preserve-search="true"
            placeholder
            noResult="Nenhuma opção encontrada, experimente digitar outras palavras"
            noOptions="Não há opções para mostrar"
            selectLabel
            selectedLabel="Selecionado"
            deselectLabel
            label="name"
            track-by="name"
            class="w-100"
          />
        </b-form-group>
      </b-form-row>
    </b-modal>
  </div>
  <!-- / .chat-wrapper -->
</template>

<!-- Page -->
<style src="@/vendor/styles/pages/chat.scss" lang="scss"></style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style
  src="@/vendor/libs/vue-multiselect/vue-multiselect.scss"
  lang="scss"
></style>
<style scoped>
.multiselect__tags-wrap {
  display: table-cell !important;
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #00337e;
}
</style>

<script>
import chat_service from "@/services/chat/chat";
import message_service from "@/services/chat/messages";
import users_service from "@/services/auth/users";
import auth_service from "@/services/auth/auth";
import utils from "@/utils/utils";
import loading from "@/utils/loading";
import * as _ from "lodash";
import shared from "@/utils/shared";

import "@/utils/event-bus";

const reflect = (p) =>
  p.then(
    (v) => ({ v, status: "fulfilled" }),
    (e) => ({ e, status: "rejected" })
  );
export default {
  name: "pages-chat",
  metaInfo: {
    title: "Chat",
  },
  components: {
    PerfectScrollbar: () =>
      import("@/vendor/libs/perfect-scrollbar/PerfectScrollbar"),
    Multiselect: () => import("vue-multiselect"),
  },
  data: () => ({
    chatsRefresh: utils.uuidv4(),
    new_message: "",
    logged_user: auth_service.getUserInformation(),
    sideboxOpen: true,

    user: {
      avatar: "1-small.png",
    },
    usersData: [],
    chatsData: [],
    chatData: {
      user: {},
      status: "Typing...",
      messages: [],
    },
    new_group: {},
    users: [],
  }),
  created() {
    window.vm = this;
    this.chatData = undefined;
    this.debouncedSearch = _.debounce(this.search, 500);

    let chat_service_loading = loading.addLoader("chat_service");
    chat_service
      .find({ workspace: shared.activeWorkspace.id })
      .then((data) => {
        this.chatsData = data;
        this.processChatsData();
      })
      .finally(() => {
        chat_service_loading.done();
      });
    let users_service_loading = loading.addLoader("users_service");
    users_service
      .find({ workspace: shared.activeWorkspace.id })
      .then((data) => {
        this.usersData = data;
      })
      .finally(() => {
        users_service_loading.done();
      });
    loading.isDone();

    $EventBus.$on("onsocketmessage", this.onmessage);
  },
  methods: {
    refreshChats() {
      this.chatsRefresh = utils.uuidv4();
    },
    selectChat(chat) {
      message_service
        .find({ to: chat.id })
        .then((data) => {
          // console.log(data);
          Object.assign(this.chatsData, chat);
          this.chatData = {
            id: chat.id,
            users: Array.from(chat.users),
          };
          this.chatData.user = {
            name: chat.name,
            online: chat.online,
            avatar: chat.avatar,
            group: chat.group,
          };

          this.chatData.messages = data;
          this.chatData.messages = this.chatData.messages.reverse();
          this.markAsRead(chat.last_message);
          this.chatData.messages.forEach(async (message) => {
            this.processMessage(this.chatData, message);
            this.markAsRead(message);
          });
        })
        .finally(() => {
          chat.unread = 0;

          let moveChat = () => {
            if (this.$refs.chat) {
              this.$refs.chat.$el.scrollTop = this.$refs.chat.$el.scrollHeight;
            } else {
              setTimeout(moveChat, 16);
            }
          };
          setTimeout(moveChat, 16);
        });
    },
    search(text) {
      if (text) {
        let chat_service_loading = loading.addLoader("chat_service");
        chat_service
          .find({ search: text })
          .then((data) => {
            if (data.length) {
              this.chatsData = data;
              this.processChatsData();
            } else {
              this.chatsData = [];
              this.usersData
                .filter((user) => {
                  let search = text
                    .toUpperCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");
                  return user.name
                    .toUpperCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(search);
                })
                .forEach((user) => {
                  this.chatsData.push({
                    id: null,
                    created_at: new Date(),
                    updated_at: new Date(),
                    groupname: "",
                    users: [user.id, this.logged_user.id],
                  });
                });
              this.processChatsData();
            }
          })
          .finally(() => {
            chat_service_loading.done();
          });
        loading.isDone();
      }
    },
    processChatsData() {
      this.chatsData.forEach((element) => {
        let users_promises = [];

        element.group = element.users.length > 2;
        element.name = element.group ? element.groupname : "...";
        element.users.forEach((user) => {
          let index = element.users.indexOf(user);
          let users_service_loading = loading.addLoader("users_service");
          users_promises.push(
            users_service
              .get(user)
              .then((data) => {
                element.users[index] = data;
                if (!element.group) {
                  if (user != this.logged_user.id) {
                    element.name = data.first_name;
                    element.avatar = data.picture_thumb
                      ? this.loadServerImg(data)
                      : false;
                  }
                }
                this.refreshChats();
              })
              .finally(() => {
                users_service_loading.done();
              })
          );
        });

        Promise.all(users_promises.map(reflect)).then(() => {
          message_service.find({ to: element.id, limit: 1 }).then((resp) => {
            resp = resp.results;
            element.last_message = resp.length ? resp[0] : null;
            if (element.last_message) {
              this.refreshChats();
              let parseObj = (obj) => {
                return JSON.parse(JSON.stringify(obj));
              };
              this.processMessage(element, element.last_message);
            }
          });
        });
      });
    },
    loadServerImg: users_service.loadServerImg,
    send_message() {
      if (this.new_message) {
        let copy = {
          creator: this.logged_user.id,
          to: this.chatData.id,
          message: this.new_message,
          user_read: [this.logged_user.id],
        };

        if (!copy.to) {
          let chat = {
            groupname: "...",
            users: this.chatData.users.map((user) => user.id),
          };
          chat_service.add(chat).then((resp) => {
            copy.to = resp.id;
            message_service.add(copy).then((resp) => {
              this.processMessage(this.chatData, resp);
              this.chatData.messages.push(resp);

              this.chatsData.forEach(async (chat) => {
                if (chat.id == resp.to) {
                  chat.last_message = resp;
                  this.processMessage(chat, resp);
                  this.refreshChats();
                }
              });
              setTimeout(() => {
                this.$refs.chat.$el.scrollTop =
                  this.$refs.chat.$el.scrollHeight;
              }, 25);
              this.new_message = "";
            });
          });
        } else {
          message_service.add(copy).then((resp) => {
            this.processMessage(this.chatData, resp);
            this.chatData.messages.push(resp);

            this.chatsData.forEach(async (chat) => {
              if (chat.id == resp.to) {
                chat.last_message = resp;
                this.processMessage(chat, resp);
                this.refreshChats();
              }
            });
            setTimeout(() => {
              this.$refs.chat.$el.scrollTop = this.$refs.chat.$el.scrollHeight;
            }, 25);
            this.new_message = "";
          });
        }
      }
    },
    async processMessage(chat, message) {
      chat.users.forEach(async (user) => {
        if (message.creator == user.id) {
          message.creator = user;
          message.creator.picture_thumb = message.creator.picture_thumb
            ? this.loadServerImg(message.creator)
            : false;
        }
      });
    },
    async markAsRead(message) {
      if (!message.user_read.includes(this.logged_user.id)) {
        message_service.get(message.id).then((resp) => {
          resp.user_read = resp.user_read.concat([this.logged_user.id]);
          message.user_read = resp.user_read;
          message_service.update(resp.id, {
            user_read: resp.user_read,
            message: resp.message,
            to: resp.to,
          });
        });
      }
    },
    onmessage(message) {
      if (message.type == "user.message") {
        message = message.data;
        if (this.chatData && this.chatData.id == message.to) {
          this.chatData.messages.push(message);
          this.chatData = Object.assign({}, this.chatData);
          this.processMessage(this.chatData, message);
          let moveChat = () => {
            if (this.$refs.chat) {
              this.$refs.chat.$el.scrollTop = this.$refs.chat.$el.scrollHeight;
            } else {
              setTimeout(moveChat, 16);
            }
          };
          setTimeout(moveChat, 16);
          this.markAsRead(message);
        }
        let found = false;
        this.chatsData.forEach((chat) => {
          if (chat.id == message.to) {
            chat.last_message = message;
            this.processMessage(chat, message);
            this.refreshChats();
            found = true;
          }
        });
        if (!found) {
          let chat_service_loading = loading.addLoader("chat_service");
          chat_service
            .find({ workspace: shared.activeWorkspace.id })
            .then((data) => {
              this.chatsData = data;
              this.processChatsData();
            })
            .finally(() => {
              chat_service_loading.done();
            });
        }
      }
    },
    addGroup() {
      let copy = {
        groupname: this.new_group.name,
        users: this.new_group.to
          .map((element) => element.id)
          .concat([this.logged_user.id]),
      };
      chat_service.add(copy).then((resp) => {
        let chat_service_loading = loading.addLoader("chat_service");
        chat_service
          .find({ workspace: shared.activeWorkspace.id })
          .then((data) => {
            this.chatsData = data;
            this.processChatsData();
          })
          .finally(() => {
            chat_service_loading.done();
          });
      });
    },
  },
  computed: {
    chatUser() {
      for (let i = 0, l = this.contactsData.length; i < l; i++) {
        if (this.contactsData[i].username === this.chatData.user)
          return this.contactsData[i];
      }
    },
    chatContacts() {
      //
      // Sort contacts (online then offline)
      //
      return []
        .concat(
          // Online
          this.contactsData
            .reduce((c, i) => {
              if (i.online) c.push(i);
              return c;
            }, [])
            .sort(function (a, b) {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();

              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            })
        )
        .concat(
          // Offline
          this.contactsData
            .reduce((c, i) => {
              if (!i.online) c.push(i);
              return c;
            }, [])
            .sort(function (a, b) {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();

              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            })
        );
    },
  },
};
</script>
