import { Note, NewNote } from '../store/types';
import noteService from "@/services/NoteService/NoteService";

export const createNote = async (note: NewNote): Promise<Note> => {
  // const newDoc = await addDoc(collection(db, collectionName), note);
  const newDoc = await noteService.add(note);
  return {
    id: newDoc.id,
    ...note
  };
};

export const getNotes = async (): Promise<Note[]> => {
  // const querySnapshot = await getDocs(collection(db, collectionName));
  const data = await noteService.all();
  return data;
};

export const updateNote = async (note: Note): Promise<Note> => {
  // const noteRef = doc(db, collectionName, note.id);

  await noteService.update(note.id, note);
  return note;
};

export const deleteNote = async (id: string): Promise<string> => {
  // const noteRef = doc(db, collectionName, id);
  await noteService.remove(id);
  return id;
};
