import Layout2 from '@/layout/Layout2'
import Dashboard from '@/pages/Dashboard/Dashboard'

export default {
    path: '/',
    component: Layout2,
    children: [
        {
            path: '',
            name: 'Dashboard',
            component: Dashboard
        }
    ]
}