import Layout2 from "@/layout/Layout2";

import Config from "@/pages/Config";
export default {
  // Layout 2
  path: "/",
  component: Layout2,
  children: [
    {
      name: "Config",
      path: "config/",
      component: Config,
    },
  ],
};
