import License from "@/pages/License/License";
import Layout2 from "@/layout/Layout2";

export default {
  // Layout 2
  path: "/",
  component: Layout2,
  children: [
    {
      name: "License",
      path: "license/",
      component: License,
    },
  ],
};
