<template>
  <v-app>
    <v-main>
      <v-container>
        <div class="header-container">
          <div>
            <p class="header-title">Bloco de Notas</p>
          </div>
          <div></div>
        </div>

        <Create style="margin-bottom: 30px" />

        <div class="text-center mt-2" v-if="loading">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>

        <Notes v-else />

        <NoteModal />

        <v-snackbar v-model="snackbar.open" :color="snackbar.color">{{
          snackbar.text
        }}</v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
/* eslint-disable */

import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Snackbar, Note } from "./store/types";
import loadingMaqLarem from "@/utils/loading";

const notesModule = namespace("notes");
const globalModule = namespace("global");

@Component({
  metaInfo: {
    title: "Bloco de notas",
  },
  components: {
    Create: () => import("./components/Create.vue"),
    Notes: () => import("./components/Notes.vue"),
    NoteModal: () => import("./components/NoteModal.vue"),
  },
})
class App extends Vue {
  loading = true;
  refreshLoading = false;

  async created() {
    this.loading = true;
    await this.getNotes();
    this.loading = false;
    loadingMaqLarem.isDone();
  }

  async refresh() {
    this.refreshLoading = true;
    await this.getNotes();
    this.refreshLoading = false;
  }

  @globalModule.State
  snackbar!: Snackbar;

  @notesModule.Action
  getNotes!: () => Promise<Note[]>;
}
export default App;
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  /* Adiciona espaçamento abaixo do cabeçalho */
}

.header-title {
  font-size: 30px;
  text-align: center;
  margin: 0;
}
</style>
