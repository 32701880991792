import generic_api from '@/services/generic_api'
import axios from 'axios'
import auth_service from '@/services/auth/auth'

import utils from '@/utils/utils'
import areas from '@/services/general/areas';
import sectors from "@/services/general/sectors"


let tasks = new generic_api('/tasks')

tasks.statuses = {
    0: "Parada",
    1: "Em Andamento",
    2: "Concluido",
    3: "Cancelado"
}

tasks.priorities = {
    1: "Importante",
    2: "Normal",
    3: "Baixa",
    4: "Dispensável",
    5: "Supérfluo",
    6: "Dispensável",
    7: "Supérfluo"
}

tasks._add = tasks.add

tasks.add = (data, callback) => {
    let new_data = {}

    Object.assign(new_data, data)

    if (!callback) {
        return tasks._add(new_data)
    } else {
        tasks._add(new_data, callback)
    }

}

tasks.add_comment = (data, callback) => {
    axios.post(`${tasks.url}${data.task_id}/add_comment/`, data).then(resp => {
        callback(resp.data)
    }).catch(reason => {
        console.log(reason)
    })
}

tasks.markAsDone = (task_data, callback) => {
    let data = {};
    Object.assign(data, task_data)
    data.status = tasks.status.CONCLUIDO;
    data.project = data.project.id
    axios.put(`${tasks.url}${data.id}/`, data).then((resp) => {
        callback(resp.data)
    }).catch((reason) => {
        console.log(reason)
    })
}
tasks.add_annotation = (id, data, callback) => {
    delete tasks.objects[id]
    axios.post(`${tasks.url}${id}/add_annotation/`, { new_annotation: data.annotation }).then(resp => {
        callback(resp.data)
    }).catch(reason => {
        console.log(reason)
    })
}

tasks.base_object = {
    name: "",                               //String
    guidelines: [],
    get creator() {
        //UserObject
        let user = auth_service.getUserInformation()
        return {
            id: user.id,
            name: user.name
        }
    },
    status: 0,
    category: "",                           //String
    start_date: new Date(),                 //
    expected_finish_date: "",               //
    priority: 2,
    responsible: [],                        //
    contributors: [],                       //
    tags: [],                                //
    // area: areas.base_object,
    // sector: sectors.base_object,
    discussion: [],
    annotations:[]
}


export default tasks