var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',[_c('h3',{staticClass:"font-weight-bold py-3 mb-4"},[_vm._v("Relatório")]),_c('b-row',[(_vm.user.account_type == 0)?_c('b-col',{attrs:{"cols":"3"}},[_c('label',{staticClass:"form-label"},[_vm._v("Tipo de relatório")]),_c('multiselect',{attrs:{"options":_vm.select_choices,"multiple":false,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"","noResult":"Nenhuma opção encontrada, experimente digitar outras palavras","noOptions":"Não há opções para mostrar","selectLabel":"","selectedLabel":"Selecionado","deselectLabel":"","label":"name","track-by":"name"},model:{value:(_vm.reportType),callback:function ($$v) {_vm.reportType=$$v},expression:"reportType"}})],1):_vm._e(),(_vm.user.account_type == 0 && _vm.reportType.value == 0)?_c('b-col',{attrs:{"cols":"3"}},[_c('label',{staticClass:"form-label"},[_vm._v("Usuário")]),_c('multiselect',{attrs:{"options":_vm.users,"multiple":false,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"placeholder":"","noResult":"Nenhuma opção encontrada, experimente digitar outras palavras","noOptions":"Não há opções para mostrar","selectLabel":"","selectedLabel":"Selecionado","deselectLabel":"","label":"name","track-by":"name"},model:{value:(_vm.selected_user),callback:function ($$v) {_vm.selected_user=$$v},expression:"selected_user"}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"col-md mb-4 date_picker_custom",staticStyle:{"min-width":"16rem"}},[_c('label',{staticClass:"form-label"},[_vm._v("Busca por data(s)")]),_c('b-input-group',[_c('flat-pickr',{ref:"filterpicker",attrs:{"config":_vm.filterpicker_config},on:{"on-change":_vm.filterpicker_model,"on-close":(selectedDates, dateStr, instance) => {
                  if (selectedDates.length == 1) {
                    instance.setDate(
                      [selectedDates[0], selectedDates[0]],
                      true
                    );
                  }
                }},model:{value:(_vm.filterpicker_model_i),callback:function ($$v) {_vm.filterpicker_model_i=$$v},expression:"filterpicker_model_i"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"px-2",attrs:{"variant":"secondary"},on:{"click":() => {
                    _vm.filterpicker_model_i = '';
                    _vm.$set(_vm.filters, 'expected_finish_date_gte', undefined);
                    _vm.$set(_vm.filters, 'expected_finish_date_lte', undefined);
                  }}},[_c('i',{staticClass:"fas fa-times",staticStyle:{"font-size":"12px"}})]),_c('b-dd',{attrs:{"size":"sm","right":true}},[_c('b-dd-item-btn',{on:{"click":() => {
                      let d1 = new Date(),
                        d2 = new Date();
                      _vm.$refs.filterpicker.fp.setDate([d1, d2], true);
                    }}},[_vm._v("Hoje")]),_c('b-dd-item-btn',{on:{"click":() => {
                      let d1 = new Date(),
                        d2 = new Date();
                      d2.setDate(d2.getDate() - 7);
                      _vm.$refs.filterpicker.fp.setDate([d1, d2], true);
                    }}},[_vm._v("Últimos 7 dias")]),_c('b-dd-item-btn',{on:{"click":() => {
                      let d1 = new Date(),
                        d2 = new Date();
                      d2.setDate(d2.getDate() - 15);
                      _vm.$refs.filterpicker.fp.setDate([d1, d2], true);
                    }}},[_vm._v("Últimos 15 dias")]),_c('b-dd-item-btn',{on:{"click":() => {
                      let d1 = new Date(),
                        d2 = new Date();
                      d2.setMonth(d2.getMonth() - 1);
                      _vm.$refs.filterpicker.fp.setDate([d1, d2], true);
                    }}},[_vm._v("Últimos 30 dias")])],1)],1)],1)],1)])],1),(_vm.reportType.value == 1)?_c('b-table',{ref:"table",staticClass:"card-table",attrs:{"items":_vm.reportData,"fields":_vm.fields,"no-local-sorting":true,"outlined":true,"small":true}}):_c('div',[_c('h5',{staticClass:"font-weight-bold py-3 mb-4"},[_vm._v(" Relatório analitico de "+_vm._s(_vm.user.name)+" ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('LineChart',{attrs:{"tasks":_vm.reportData,"dateStart":_vm.filters.created_at__gte,"dateFinish":_vm.filters.created_at__lte}})],1),_c('b-col',{attrs:{"cols":"2"}}),_c('b-col',{attrs:{"cols":"5"}},[_c('PieChart',{attrs:{"tasks":_vm.reportData,"dateStart":_vm.filters.created_at__gte,"dateFinish":_vm.filters.created_at__lte}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }