import Layout2 from '@/layout/Layout2'
import RoutineTasksNav from '@/pages/Tasks/RoutineTasksNav';

export default {
    // Layout 2
    path: '/',
    component: Layout2,
    children: [
        {
            name: "RoutineTasksNav",
            path: "routine-tasks",
            component: RoutineTasksNav
        }
    ]
}