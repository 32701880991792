import config from "@/config";
import axios from "axios";
import { log } from "util";

const LOCAL_TOKEN_KEY = "UserToken";
const LOCAL_NAME_KEY = "UserName";
const LOCAL_PHOTO_KEY = "UserPhoto";
const LOCAL_ID_KEY = "UserID";
const LOCAL_IS_STAFF = "UserIsStaff";
const LOCAL_ACCOUNT_TYPE = "UserAccountType";
const LOCAL_NOTIFICATIONS_KEY = "UserNotificationsKey";
const LOCAL_CAN_CREATE = "UserCanCreateKey";
const LOCAL_AREA = "UserAreaKey";
const LOCAL_SECTOR = "UserSectorKey";

function _checkBeforeStore(key, value) {
  // AVOID SET NULL VALUE AS STRING 'NULL'
  if (value != null) {
    window.localStorage.setItem(key, value);
  }
}

class auth_service {
  constructor() {
    this.isAuthenticated = false;
    this.authToken = undefined;

    this.loadUserCredentials();
  }

  loadUserCredentials() {
    var sessionToken = window.localStorage.getItem(LOCAL_TOKEN_KEY);
    if (sessionToken) {
      this.useCredentials(sessionToken);
    } else {
      var localToken = window.localStorage.getItem(LOCAL_TOKEN_KEY);
      if (localToken) {
        this.useCredentials(localToken);
      }
    }
  }

  storeUserCredentials(token) {
    window.localStorage.setItem(LOCAL_TOKEN_KEY, token);
    this.useCredentials(token);
  }

  storeUserInformation(user) {
    console.log(user);

    _checkBeforeStore(LOCAL_NAME_KEY, user.name);
    _checkBeforeStore(LOCAL_PHOTO_KEY, user.photo);
    _checkBeforeStore(LOCAL_ID_KEY, user.id);
    _checkBeforeStore(LOCAL_IS_STAFF, user.is_staff);
    _checkBeforeStore(LOCAL_ACCOUNT_TYPE, user.account_type);
    _checkBeforeStore(LOCAL_NOTIFICATIONS_KEY, user.notifications_key);
    _checkBeforeStore(LOCAL_CAN_CREATE, user.can_create);
    _checkBeforeStore(LOCAL_AREA, user.area);
    _checkBeforeStore(LOCAL_SECTOR, user.sector);
  }

  getUserInformation() {
    let photo = `${config.https ? "https" : "http"}://${config.api.host}:${
      config.api.port
    }${window.localStorage.getItem(LOCAL_PHOTO_KEY)}`;
    return {
      name: window.localStorage.getItem(LOCAL_NAME_KEY),
      photo: photo,
      id: window.localStorage.getItem(LOCAL_ID_KEY),
      is_staff: window.localStorage.getItem(LOCAL_IS_STAFF),
      account_type: window.localStorage.getItem(LOCAL_ACCOUNT_TYPE),
      notifications_key: window.localStorage.getItem(LOCAL_NOTIFICATIONS_KEY),
      can_create: window.localStorage.getItem(LOCAL_CAN_CREATE),
      area:window.localStorage.getItem(LOCAL_AREA),
      sector:window.localStorage.getItem(LOCAL_SECTOR),
    };
  }

  useCredentials(token) {
    this.isAuthenticated = true;
    this.authToken = token;

    window.localStorage.setItem(LOCAL_TOKEN_KEY, token);

    //Set the token as header
    axios.defaults.headers.common.Authorization = this.authToken;
  }

  destroyUserCredentials() {
    this.authToken = undefined;
    this.isAuthenticated = false;
    axios.defaults.headers.common.Authorization = undefined;
    window.localStorage.removeItem(LOCAL_TOKEN_KEY);
    window.localStorage.removeItem(LOCAL_TOKEN_KEY);
    window.localStorage.removeItem(LOCAL_NAME_KEY);
    window.localStorage.removeItem(LOCAL_PHOTO_KEY);
    window.localStorage.removeItem(LOCAL_ID_KEY);
    window.localStorage.removeItem(LOCAL_IS_STAFF);
    window.localStorage.removeItem(LOCAL_ACCOUNT_TYPE);
    window.localStorage.removeItem(LOCAL_NOTIFICATIONS_KEY);
  }

  logout() {
    this.destroyUserCredentials();
  }

  login(userLogin, successCallback, errorCallback) {
    axios({
      method: "POST",
      url: `${config.https ? "https" : "http"}://${config.api.host}:${
        config.api.port
      }/api/login/`,
      data: userLogin,
    })
      .then((resp) => {
        let data = resp.data;
        var formattedToken = `Token ${data.token}`;

        this.storeUserInformation(data);
        if (userLogin.rememberMe) {
          this.storeUserCredentials(formattedToken);
        } else {
          this.useCredentials(formattedToken);
        }

        if (successCallback) {
          successCallback(data);
        }
      })
      .catch((error) => {
        if (errorCallback) {
          errorCallback(error.data);
        }
      });
    // .catch((error) =>{
    //   if (errorCallback) {errorCallback(data);}
    //   console.log(error)
    // });
    // }).success(function(data) {

    // }).catch(function(data) {
    //
    // });
  }
}

export default new auth_service();
