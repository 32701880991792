import generic_api from "@/services/generic_api";
import axios from "axios";
import auth_service from "@/services/auth/auth";

let tasks = new generic_api("/tasks/routine");

tasks.statuses = {
	0: "Vencida",
	1: "Não iniciada",
	2: "Concluída",
};

tasks.root_routine = {
	1: true,
	0: false,
};

tasks.priorities = {
	1: "Três Estrelas",
	2: "Duas Estrelas",
	3: "Uma Estrela",
};

tasks.repeat = {
	0: "Diário",
	1: "Semanal",
	7: "Quinzenal",
	2: "Mensal",
	3: "Trimestral",
	4: "Anual",
	5: "Semestral",
	6: "Por Demanda",
};

tasks._add = tasks.add;

tasks.add = (data, callback) => {
	let new_data = {};

	Object.assign(new_data, data);
	console.log(new_data);
	if (!callback) {
		return tasks._add(new_data);
	} else {
		tasks._add(new_data, callback);
	}
};

tasks.add_comment = (data, callback) => {
	axios
		.post(`${tasks.url}${data.task_id}/add_comment/`, data)
		.then((resp) => {
			callback(resp.data);
		})
		.catch((reason) => {
			console.log(reason);
		});
};

tasks.markAsDone = (task_data, callback) => {
	let data = {};
	Object.assign(data, task_data);
	data.status = tasks.status.CONCLUIDO;
	data.project = data.project.id;
	axios
		.put(`${tasks.url}${data.id}/`, data)
		.then((resp) => {
			callback(resp.data);
		})
		.catch((reason) => {
			console.log(reason);
		});
};
tasks.add_annotation = (id, data, callback) => {
	delete tasks.objects[id];
	axios
		.post(`${tasks.url}${id}/add_annotation/`, {
			new_annotation: data.annotation,
		})
		.then((resp) => {
			callback(resp.data);
		})
		.catch((reason) => {
			console.log(reason);
		});
};

tasks.base_object = {
	name: "", //String
	guidelines: [],
	get creator() {
		//UserObject
		let user = auth_service.getUserInformation();
		return {
			id: user.id,
			name: user.name,
		};
	},
	status: 1,
	root_routine: "",
	category: "", //String
	start_date: new Date(),
	finish_date: "", //
	expected_finish_date: "", //
	priority: 2, //
	responsible: [], //
	contributors: [], //
	tags: [],
	repeat: 0,
	discussion: [],
};

export default tasks;
