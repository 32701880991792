<template>
  <div class="px-5">
    <div class="media align-items-center py-3 mb-3">
      <img :src="loadServerImg(userData)" alt class="d-block ui-w-100 rounded-circle" />
      <div class="media-body ml-4">
        <h4 class="font-weight-bold mb-0">
          {{userData.name}}
        </h4>
        <div class="text-muted mb-2">ID: {{userData.id}}</div>
        <b-btn
          variant="primary"
          size="sm"
          :to="{name:'UsersEdit', params:{id:this.$route.params.id}}">Editar</b-btn>&nbsp;
      </div>
    </div>
    <b-card no-body class="mb-4">
      <b-card-body>
        <table class="table user-view-table m-0">
          <tbody>
            <tr>
              <td>Registrado em:</td>
              <td>{{userData.date_joined|date}}</td>
            </tr>
            <tr>
              <td>Último Login:</td>
              <td>{{userData.last_login|date}}</td>
            </tr>
            <tr>
              <td>Tipo de usuário:</td>
              <td>
                <span>{{account_types[userData.account_type]}}</span>
              </td>
            </tr>
            <tr>
              <td>Cargo do usuário usuário:</td>
              <td>
                <span>{{userData.account_type}}</span>
              </td>
            </tr>
            <tr>
              <td>Status:</td>
              <td>
                <b-badge variant="outline-success" v-if="userData.status === 1">Active</b-badge>
                <b-badge variant="outline-danger" v-if="userData.status === 2">Banned</b-badge>
                <b-badge variant="outline-default" v-if="userData.status === 3">Deleted</b-badge>
              </td>
            </tr>
            <tr>
              <td>Redirecionamento estratégico:</td>
              <td>
                <span>{{userData.user_function}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </b-card-body>
      <hr class="border-light m-0" />
      <div class="table-responsive">
        <b-table :items="userData.permissions" class="card-table m-0">
          <template v-slot:cell(read)="data">
            <span
              class="ion"
              :class="{'ion-md-checkmark text-primary': data.item.read, 'ion-md-close text-light': !data.item.read}"
            ></span>
          </template>
          <template v-slot:cell(write)="data">
            <span
              class="ion"
              :class="{'ion-md-checkmark text-primary': data.item.write, 'ion-md-close text-light': !data.item.write}"
            ></span>
          </template>
          <template v-slot:cell(create)="data">
            <span
              class="ion"
              :class="{'ion-md-checkmark text-primary': data.item.create, 'ion-md-close text-light': !data.item.create}"
            ></span>
          </template>
          <template v-slot:cell(delete)="data">
            <span
              class="ion"
              :class="{'ion-md-checkmark text-primary': data.item.delete, 'ion-md-close text-light': !data.item.delete}"
            ></span>
          </template>
        </b-table>
      </div>
    </b-card>

    <b-card no-body class="mb-4">
      <div class="row no-gutters row-bordered">

        <div class="d-flex col-md align-items-center">
          <a href="javascript:void(0)" class="card-body d-block text-dark">
            <div class="text-muted small line-height-1">Tarefas pendentes que estou responsável</div>
            <div class="text-xlarge">{{formatInt(userData.pending_tasks)}}</div>
          </a>
        </div>

        <div class="d-flex col-md align-items-center">
          <a href="javascript:void(0)" class="card-body d-block text-dark">
            <div class="text-muted small line-height-1">Tarefas concluídas que estou responsável</div>
            <div class="text-xlarge">{{formatInt(userData.done_tasks)}}</div>
          </a>
        </div>
      </div>
      <hr class="border-light m-0" />
      <b-card-body>
        <table class="table user-view-table m-0">
          <tbody>
            <tr>
              <td>E-mail:</td>
              <td>{{userData.email}}</td>
            </tr>
          </tbody>
        </table>

        <h6 class="mt-4 mb-3">Informações Pessoais</h6>

        <table class="table user-view-table m-0">
          <tbody>
            <tr>
              <td>Nome completo:</td>
              <td>{{userData.first_name}} {{userData.last_name}}</td>
            </tr>
            <tr v-if="userData.phone">
              <td>Telefone:</td>
              <td>{{userData.phone}}</td>
            </tr>
            <tr v-if="userData.birthday">
              <td>Aniversário:</td>
              <td>{{userData.birthday}}</td>
            </tr>
          </tbody>
        </table>
      </b-card-body>
    </b-card>
  </div>
</template>

<!-- Page -->
<style src="@/vendor/styles/pages/users.scss" lang="scss"></style>

<script>
import * as numeral from "numeral";
import auth_service from "@/services/auth/auth";
import users_service from "@/services/auth/users";
import * as _ from "lodash";
import loading from "@/utils/loading";
import utils from "@/utils/utils";
import area_service from "@/services/general/areas";
import sectors_service from "@/services/general/sectors";

import shared from "@/utils/shared";

const { uuidv4 } = utils;

export default {
  name: "pages-user-view",
  metaInfo: {
    title: "Detalhes Usuário"
  },

  data: () => ({
    logged_user: auth_service.getUserInformation(),
    account_types: {
      0: "Diretor",
      1: "Gestor",
      2: "Colaborador"
    },

    userData: {
      picture_thumb: "5-small.png",
      name: "Carregando...",
      email: "",
      company: "",
      id: "",
      date_joined: "",
      last_login: "",
      account_type: 1,
      status: 1,

      permissions: [],

      // Statistics
      comments: "",
      pending_tasks: "",
      done_tasks: "",

      phone: "",
      birthday: ""
    },

    done: {
      user: false
    },
    users: [],
    areas: [],
    sectors: []
  }),
  created() {
    window.vm = this;
    let users_service_loading = loading.addLoader("users_service");
    if (this.$route.params.id == "me") {
      let user = auth_service.getUserInformation();
      users_service
        .get(user.id)
        .then(this.user_find_callback)
        .finally(() => {
          users_service_loading.done();
        });
    } else {
      users_service
        .get(this.$route.params.id)
        .then(this.user_find_callback)
        .finally(() => {
          users_service_loading.done();
        });
    }
    this.refresh();
  },
  methods: {
    formatInt(v) {
      return numeral(v).format("0,0");
    },
    user_find_callback(resp) {
      this.userData = resp;

      //Convert dates from string to number
      this.userData.date_joined = Number(this.userData.date_joined)*1000
      this.userData.last_login = Number(this.userData.last_login)*1000
      this.userData.brainstorming = JSON.parse(
        this.userData.brainstorming
          ? this.userData.brainstorming
          : JSON.stringify([])
      );
    },
    refresh() {
      let users_service_loading = loading.addLoader("users_service");
      users_service
        .find({ workspace: shared.activeWorkspace.id })
        .then(resp => {
          let copy = [];
          resp.forEach(user => {
            if (user.is_active) {
              copy.push(user);
            }
          });
          this.users = copy;
        })
        .finally(() => {
          users_service_loading.done();
        });

      let area_service_loading = loading.addLoader("area_service");
      area_service
        .find({ workspace: shared.activeWorkspace.id })
        .then(resp => {
          this.areas = resp;
        })
        .finally(() => {
          area_service_loading.done();
        });

      let sectors_service_loading = loading.addLoader("sectors_service");
      sectors_service
        .find({ workspace: shared.activeWorkspace.id })
        .then(resp => {
          this.sectors = resp;
        })
        .finally(() => {
          sectors_service_loading.done();
        });

      loading.isDone();
    },
    loadServerImg: users_service.loadServerImg
  },
  filters: {
    date(obj) {
      try {
        if (obj) {
          let date = new Date(obj);
          return date.toLocaleDateString();
        } else {
          return "Nunca Entrou";
        }
      } catch (error) {
        console.error(error);
        return obj;
      }
    }
  }
};
</script>
