
import Layout2 from '@/layout/Layout2'

import UsersView from '@/pages/User/UsersView';
import UsersEdit from '@/pages/User/UsersEdit';
import UsersList from '@/pages/User/UsersList';
import UsersCreate from '@/pages/User/UsersCreate';
import Brainstorm from '@/pages/User/Brainstorm';
// import Notepad from '@/pages/User/Notepad';
import Notepad from '@/pages/Keep/KeepPage'
import Directing from '@/pages/User/StrategicRedirection';

export default {
    // Layout 2
    path: '/',
    component: Layout2,
    children: [
        {
            name: "UsersBrainstorm",
            path: "users/brainstorm",
            component: Brainstorm
        },
        {
            name: "UsersNotepad",
            path: "users/notepad",
            component: Notepad
        },
        {
            name: "UsersView",
            path: "users/:id",
            component: UsersView
        },
        {
            name: "UsersEdit",
            path: "users/:id/edit",
            component: UsersEdit
        },
        {
            name: "UsersList",
            path: "users/",
            component: UsersList
        },
        {
            name: "UsersCreate",
            path: "new/user/",
            component: UsersCreate
        },
        {
            name: "UsersDirecting",
            path: "users/directing",
            component: Directing
        },
    ]
}