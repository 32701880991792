let https = Boolean(process.env.VUE_APP_TAS_HTTPS)

export default {
    api: {
        host: process.env.VUE_APP_TAS_HOST,
        port: process.env.VUE_APP_TAS_PORT_HTTP || (https ? 443 : 80),
    },
    websocket: {
        host: process.env.VUE_APP_TAS_HOST,
        port: process.env.VUE_APP_TAS_PORT_HTTP || (https ? 443 : 80),
    },
    https: https
}