import Layout2 from '@/layout/Layout2'

import Chat from '@/pages/Chat/Chat'

export default {
    path: '/chat',
    component: Layout2,
    children: [
        {
            name: "Chat",
            path: "",
            component: Chat
        }
    ]
}