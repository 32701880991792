export enum SnackbarColorTypes {
  Success = 'success',
  Error = 'error',
  Info = 'info'
}

export enum CardColorTypes {
  Nenhuma = 'none',
  Vermelho = 'red accent-1',
  Laranja = 'orange accent-2',
  Amarelo = 'yellow accent-1',
  Verde = 'light-green accent-1',
  Azul = 'cyan lighten-4',
  Roxo = 'purple lighten-4',
  Rosa = 'pink lighten-4'
}

export interface Note {
  id: string;
  title: string;
  content: string;
  color: string;
}

export type NewNote = Omit<Note, 'id'>;

export interface Snackbar {
  open: boolean;
  text: string;
  color: SnackbarColorTypes;
}

export interface EditNoteField {
  name: string;
  value: string;
}

export type CardColors = keyof typeof CardColorTypes;