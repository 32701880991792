<template>
  <div class="px-5">
    <h4 class="font-weight-bold py-3 mb-4">
      Editar Usuário
      <span class="text-muted">#{{ userData.email }}</span>
    </h4>

    <b-tabs class="nav-tabs-top nav-responsive-sm" :key="update">
      <b-tab title="Conta" active>
        <b-card-body>
          <div class="media align-items-center">
            <img :src="user_picture_thumb()" alt class="d-block ui-w-80" />
            <div class="media-body ml-3">
              <label class="form-label d-block mb-2">Foto</label>
              <b-btn variant="outline-primary" size="sm" v-b-modal.modals-file
                >Mudar</b-btn
              >&nbsp;
              <!-- <b-btn variant="default md-btn-flat" size="sm">Reset</b-btn> -->
            </div>
          </div>
        </b-card-body>
        <hr class="border-light m-0" />
        <b-card-body class="pb-2">
          <b-form-group label="Email">
            <b-input v-model="userData.email" class="mb-1" />
          </b-form-group>

          <b-form-group label="Notificações">
            <div class="m-1">
              <label class="switcher">
                <input
                  type="checkbox"
                  class="switcher-input"
                  v-model="userData.send_email"
                />
                <span class="switcher-indicator">
                  <span class="switcher-yes"></span>
                  <span class="switcher-no"></span>
                </span>
                <span class="switcher-label"
                  >Receber notificações via email</span
                >
              </label>
            </div>
            <div class="m-1">
              <label class="switcher">
                <input
                  type="checkbox"
                  class="switcher-input"
                  v-model="userData.notify.creator"
                />
                <span class="switcher-indicator">
                  <span class="switcher-yes"></span>
                  <span class="switcher-no"></span>
                </span>
                <span class="switcher-label"
                  >Receber notificações das tarefas criadas por mim</span
                >
              </label>
            </div>
            <div class="m-1">
              <label class="switcher">
                <input
                  type="checkbox"
                  class="switcher-input"
                  v-model="userData.notify.responsible"
                />
                <span class="switcher-indicator">
                  <span class="switcher-yes"></span>
                  <span class="switcher-no"></span>
                </span>
                <span class="switcher-label"
                  >Receber notificações das tarefas que sou responsável</span
                >
              </label>
            </div>
            <div class="m-1">
              <label class="switcher">
                <input
                  type="checkbox"
                  class="switcher-input"
                  v-model="userData.notify.contributor"
                />
                <span class="switcher-indicator">
                  <span class="switcher-yes"></span>
                  <span class="switcher-no"></span>
                </span>
                <span class="switcher-label"
                  >Receber notificações das tarefas que participo</span
                >
              </label>
            </div>

            <div class="m-1">
              <label class="switcher">
                <input
                  type="checkbox"
                  class="switcher-input"
                  v-model="userData.notify.guidelines"
                />
                <span class="switcher-indicator">
                  <span class="switcher-yes"></span>
                  <span class="switcher-no"></span>
                </span>
                <span class="switcher-label"
                  >Receber notificações de diretrizes</span
                >
              </label>
            </div>
          </b-form-group>
        </b-card-body>
        <hr class="border-light m-0" />
        <b-card-body class="pb-2">
          <b-form-group label="Cargo do usuário">
            <b-input v-model="userData.user_function" class="mb-1" />
          </b-form-group>
          <b-form-group label="Tipo de usuário">
            <b-select
              v-model="userData.account_type"
              :options="account_types"
              :disabled="logged_user.account_type != 0"
            />
          </b-form-group>

          <b-form-group label="Status">
            <b-select
              v-model="userData.status"
              :options="{ 1: 'Ativo', 0: 'Desativado' }"
              :disabled="
                this.$route.params.id == 'me' ||
                this.$route.params.id == logged_user.id
              "
            />
          </b-form-group>
        </b-card-body>
        <hr class="border-light m-0" />
        <b-card-body class="pb-2">
          <b-form-group label="Area">
            <div
              class="mb-1 mr-1 d-inline-block"
              v-for="a in userData.area"
              :key="a.id"
            >
              <b-btn
                size="sm"
                variant="primary"
                @click="userData.area.splice(userData.area.indexOf(r), 1)"
              >
                {{ a.name }}
                <i class="ion ion-md-close"></i>
              </b-btn>
            </div>
            <customTypeahead
              v-model="userData.area"
              :options="areas"
              :objKey="'name'"
              :add="false"
              :multiple="true"
            />
          </b-form-group>

          <b-form-group label="Area/Setor">
            <div
              class="mb-1 mr-1 d-inline-block"
              v-for="s in userData.sector"
              :key="s.id"
            >
              <b-btn
                size="sm"
                variant="primary"
                @click="
                  userData.sector.splice(userData.sector.indexOf(r), 1);
                  data.item.changed.responsible = true;
                "
              >
                {{ s.name }}
                <i class="ion ion-md-close"></i>
              </b-btn>
            </div>
            <customTypeahead
              v-model="userData.sector"
              :options="sectors"
              :objKey="'name'"
              :add="false"
              :multiple="true"
            />
          </b-form-group>

          <b-form-group label="Workspaces">
            <div
              class="mb-1 mr-1 d-inline-block"
              v-for="s in userData.workspaces"
              :key="s.id"
            >
              <b-btn
                size="sm"
                variant="primary"
                @click="
                  userData.workspaces.splice(userData.workspaces.indexOf(s), 1);
                  data.item.changed.workspaces = true;
                "
              >
                {{ s.name }}
                <i class="ion ion-md-close"></i>
              </b-btn>
            </div>
            <customTypeahead
              v-model="userData.workspaces"
              :options="workspaces"
              :objKey="'name'"
              :add="false"
              :multiple="true"
            />
          </b-form-group>
        </b-card-body>
        <div class="table-responsive">
          <!-- <b-table :items="userData.permissions" class="card-table m-0">
              <b-check v-model="data.item.read" class="px-2 m-0" />
            <template slot="read" slot-scope="data">
            </template>
            <template slot="write" slot-scope="data">
              <b-check v-model="data.item.write" class="px-2 m-0" />
            </template>
            <template slot="create" slot-scope="data">
              <b-check v-model="data.item.create" class="px-2 m-0" />
            </template>
            <template slot="delete" slot-scope="data">
              <b-check v-model="data.item.delete" class="px-2 m-0" />
            </template>
          </b-table>-->
        </div>
      </b-tab>
      <b-tab title="Sobre">
        <hr class="border-light m-0" />
        <b-card-body class="pb-2">
          <h6 class="mb-4">Dados Pessoais</h6>

          <b-form-group label="Nome">
            <b-input v-model="userData.first_name" />
          </b-form-group>

          <b-form-group label="Sobrenome">
            <b-input v-model="userData.last_name" />
          </b-form-group>

          <b-form-group label="Telefone">
            <b-input v-model="userData.phone" />
          </b-form-group>

          <b-form-group label="Aniversário">
            <datepicker
              v-model="userData.birthday"
              :bootstrapStyling="true"
              :monday-first="true"
              :full-month-name="true"
              placeholder="Selecione a data de nascimento"
              :calendar-button="true"
              calendar-button-icon="ion ion-md-calendar"
              :clear-button="true"
              :language="languages[language]"
              :format="customFormatter"
            />
          </b-form-group>
        </b-card-body>
      </b-tab>
      <b-tab title="Segurança">
        <hr class="border-light m-0" />
        <b-card-body class="pb-2">
          <h6 class="mb-4">Senha</h6>
          <div v-if="logged_user.account_type == 0">
            <b-form-group label="Insira a Senha">
              <b-input type="password" v-model="userData.password1" />
            </b-form-group>

            <b-form-group label="Repita a senha">
              <b-input type="password" v-model="userData.password2" />
            </b-form-group>
          </div>
          <div
            v-if="
              logged_user.account_type != 0 && logged_user.id == userData.id
            "
          >
            <b-form-group label="Insira a senha antiga">
              <b-input type="password" v-model="userData.password" />
            </b-form-group>
            <b-form-group label="Insira a Senha">
              <b-input type="password" v-model="userData.password1" />
            </b-form-group>

            <b-form-group label="Repita a senha">
              <b-input type="password" v-model="userData.password2" />
            </b-form-group>
          </div>
        </b-card-body>
      </b-tab>
      <!-- Alteração de Nomeclatura (Nicoly & David) !-->
      <b-tab title="Direcionamento" v-if="logged_user.account_type == 0">
        <hr class="border-light m-0" />
        <b-card-body class="pb-2">
          <h6 class="mb-4">Direcionamento estratégico</h6>
          <div v-if="logged_user.account_type == 0">
            <b-form-group label="Digite aqui o Direcionamento">
              <b-textarea type="text" v-model="userData.directing" row="10" />
            </b-form-group>
          </div>
        </b-card-body>
      </b-tab>
    </b-tabs>

    <div class="text-right mt-3">
      <b-btn variant="primary" @click="submitUpdate">Salvar alterações</b-btn
      >&nbsp;
      <b-btn
        variant="default"
        @click="
          () => {
            this.$router.push({
              name: 'UsersView',
              params: { id: this.$route.params.id },
            });
          }
        "
        >Cancel</b-btn
      >
    </div>

    <b-modal id="modals-file" ref="modalFile">
      <template v-slot:modal-title>Adicionar arquivos:</template>
      <b-form-row>
        <b-form-group label="Nome" class="col">
          <!-- <b-input-file placeholder="" v-model="user_search" /> -->
          <b-form-file
            v-model="temp_file"
            :state="Boolean(temp_file)"
            placeholder="Choose a file..."
            drop-placeholder="Drop file here..."
          />
        </b-form-group>
      </b-form-row>
      <template v-slot:modal-footer>
        <b-btn @click="closeModalFile">Close</b-btn>
        <b-btn @click="uploadFile" :variant="'primary'">Enviar</b-btn>
      </template>
    </b-modal>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style
  src="@/vendor/libs/vue-multiselect/vue-multiselect.scss"
  lang="scss"
></style>

<!-- Page -->
<style src="@/vendor/styles/pages/users.scss" lang="scss"></style>

<script>
import * as lang from "vuejs-datepicker/src/locale";

import area_service from "@/services/general/areas";
import sectors_service from "@/services/general/sectors";
import auth_service from "@/services/auth/auth";
import users_service from "@/services/auth/users";
import workspaces_service from "@/services/general/workspace";
import shared from "@/utils/shared";
import loading from "@/utils/loading";

export default {
  name: "pages-user-edit",
  metaInfo: {
    title: "Editar usuário",
  },
  components: {
    Multiselect: () => import("vue-multiselect"),
    Datepicker: () => import("vuejs-datepicker"),
    customTypeahead: () => import("@/components/Utils/CustomTypeahead.vue"),
  },
  data: () => ({
    loading,
    languages: lang,
    language: "ptBR",
    account_types: {
      0: "Diretor",
      1: "Gestor",
      2: "Colaborador",
    },
    update: 0,
    logged_user: auth_service.getUserInformation(),
    userData: {
      picture_thumb: "5-small.png",
      name: "Carregando",
      username: "...",
      email: "...",
      company: "...",
      id: "",
      verified: true,
      account_type: 1,
      status: 1,
      sector: [],
      area: [],

      permissions: [
        {
          module: "Users",
          read: true,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: "Articles",
          read: true,
          write: true,
          create: true,
          delete: false,
        },
        {
          module: "Staff",
          read: false,
          write: false,
          create: false,
          delete: false,
        },
      ],
      birthday: "May 3, 1995",
      phone: "+0 (123) 456 7891",
    },
    temp_file: "",
    done: {
      user: false,
    },
    areas: [],
    sectors: [],
    workspaces: [],
  }),
  created() {
    window.vm = this;
    this.refresh();
  },
  methods: {
    user_picture_thumb() {
      if (!this.userData.picture_thumb) {
        return "";
      }
      if (this.userData.picture_thumb.includes(";base64,")) {
        return this.userData.picture_thumb;
      } else {
        return this.loadServerImg(this.userData);
      }
    },
    user_find_callback(resp) {
      this.userData = resp;
      if (
        this.logged_user.account_type != 0 &&
        this.logged_user.id != this.userData.id
      ) {
        this.$router.push({
          name: "UsersView",
          params: {
            id: this.userData.id,
          },
        });
      } else {
        if (this.userData.area.length) {
          this.userData.area.forEach((area) => {
            let index = this.userData.area.indexOf(area);
            if (typeof area == "string") {
              let area_loading = loading.addLoader("area_service_loading");
              area_service
                .get(area)
                .then((resp) => {
                  this.userData.area[index] = resp;
                  this.userData.area = this.userData.area;
                })
                .finally(() => {
                  area_loading.done();
                  this.update++;
                });
            }
          });
        }
        if (this.userData.sector.length) {
          this.userData.sector.forEach((sector) => {
            let index = this.userData.sector.indexOf(sector);
            if (typeof sector == "string") {
              let sector_loading = loading.addLoader("sector_service_loading");
              sectors_service
                .get(sector)
                .then((resp) => {
                  this.userData.sector[index] = resp;
                  this.userData.sector = this.userData.sector;
                })
                .finally(() => {
                  sector_loading.done();
                  this.update++;
                });
            }
          });
        }
        if (this.userData.workspaces.length) {
          this.userData.workspaces.forEach((workspace) => {
            let index = this.userData.workspaces.indexOf(workspace);
            if (typeof workspace == "string") {
              let workspace_loading = loading.addLoader(
                "workspace_service_loading"
              );
              workspaces_service
                .get(workspace)
                .then((resp) => {
                  this.userData.workspaces[index] = resp;
                  this.$set(
                    this.userData,
                    "workspaces",
                    this.userData.workspaces
                  );
                })
                .finally(() => {
                  workspace_loading.done();
                  this.update++;
                });
            }
          });
        }
      }
      this.update++;
    },
    closeModalFile() {
      this.$bvModal.hide("modals-file");
      // console.log(this.temp_file);
    },
    uploadFile() {
      const formData = new FormData();
      formData.append("picture", this.temp_file);
      users_service.update(this.userData.id, formData);
      this.closeModalFile();

      function getBase64(file, callback) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          callback(reader.result);
        };
        reader.onerror = function (error) {
          console.error("Error: ", error);
        };
      }

      getBase64(this.temp_file, (result) => {
        this.userData.picture_thumb = result;
        this.userData.picture = result;
      });
    },
    refresh() {
      let users_service_loading = loading.addLoader("users_service");
      if (this.$route.params.id == "me") {
        users_service
          .get(this.logged_user.id)
          .then(this.user_find_callback)
          .finally(() => {
            users_service_loading.done();
          });
      } else {
        users_service
          .get(this.$route.params.id)
          .then(this.user_find_callback)
          .finally(() => {
            users_service_loading.done();
          });
      }

      let area_service_loading = loading.addLoader("area_service");
      area_service
        .find({ workspace: shared.activeWorkspace.id })
        .then((resp) => {
          this.areas = resp;
        })
        .finally(() => {
          area_service_loading.done();
        });

      let sectors_service_loading = loading.addLoader("sectors_service");
      sectors_service
        .find({ workspace: shared.activeWorkspace.id })
        .then((resp) => {
          this.sectors = resp;
        })
        .finally(() => {
          sectors_service_loading.done();
        });
      workspaces_service.all().then((resp) => {
        this.workspaces = resp;
      });
      loading.isDone();
    },
    submitUpdate() {
      let copy = JSON.parse(JSON.stringify(this.userData));
      copy.username = this.userData.email;
      // copy.date_joined = undefined;
      delete copy["date_joined"];
      delete copy["last_login"];
      delete copy["picture"];
      delete copy["picture_thumb"];
      // copy.last_login = undefined;
      // copy.picture = undefined;
      // copy.picture_thumb = undefined;
      // copy.is_active = false;
      copy.status = parseInt(this.userData.status);
      copy.is_active = parseInt(this.userData.status) == 1;
      let promises = [];
      Promise.all(promises).then(() => {
        copy.area = copy.area.map((area) => area.pk);
        copy.sector = copy.sector.map((sector) => sector.pk);
        copy.workspaces = copy.workspaces.map((workspace) => workspace.id);
        users_service.update(
          this.userData.id,
          copy,
          this.submitUpdate_callback
        );
      });
    },
    submitUpdate_callback() {
      this.$router.push({
        name: "UsersView",
        params: {
          id: this.userData.id,
        },
      });
    },
    customFormatter(date) {
      let day = this.zeroPadding(date.getDate(), 2);
      let month = this.zeroPadding(date.getMonth() + 1, 2);
      return `${day}/${month}`;
    },
    zeroPadding(obj, size) {
      if (typeof obj != "string") {
        obj = obj.toString();
      }

      while (obj.length < size) {
        obj = "0" + obj;
      }
      return obj;
    },
    loadServerImg: users_service.loadServerImg,
  },
};
</script>
