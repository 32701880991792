import config from "@/config";
import axios from "axios";
import shared from "@/utils/shared";

export default class {
  constructor(url) {
    if (url.charAt(url.length - 1) != "/") {
      this.url = `${config.https ? "https" : "http"}://${config.api.host}:${
        config.api.port
        }/api${url}/`;
    } else {
      this.url = `${config.https ? "https" : "http"}://${config.api.host}:${
        config.api.port
        }/api${url}`;
    }
    this.objects = {};
  }

  all(callback) {
    // console.log("active workspace:", shared.activeWorkspace);
    if (shared.activeWorkspace.selected) {
      return this.find({ workspace: shared.activeWorkspace.id }, callback);
    }
    if (callback) {
      axios
        .get(this.url)
        .then((resp) => {
          callback(resp.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .get(this.url)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  search(search="", callback) {
    // console.log("active workspace:", shared.activeWorkspace);
    if (shared.activeWorkspace.selected) {
      return this.find({ workspace: shared.activeWorkspace.id }, callback);
    }
    let url = this.url
    if (search) {
      url += `?search=${search}`
    }
    if (callback) {
      axios
        .get(url)
        .then((resp) => {
          callback(resp.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  find(params, callback, identifier, bypass) {
    if (shared.activeWorkspace.id) {
      Object.assign(params, { workspace: shared.activeWorkspace.id });
    }
    if (shared.activeWorkspace.none_selected && !bypass) {
      if (callback) {
        callback({});
      } else {
        return new Promise((resolve, reject) => {
          if (reject) {
            reject(new Error("Workspace not selected"));
          }
        });
      }
    }

    if (!this.find_call || !this.find_call.length) {
      this.find_call = [];
    }
    if (this.find_call.length) {
      this.find_call.forEach((element) => {
        if ((element.identifier == identifier)) {
          element.cancel();
        }
      });
    }
    if (identifier) {
      this.find_call.push(
        Object.assign(axios.CancelToken.source(), { identifier })
      );
    }
    if (callback) {
      axios
        .get(`${this.url}`, { params, cancelToken: this.find_call.token })
        .then((resp) => {
          callback(resp.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .get(`${this.url}`, { params, cancelToken: this.find_call.token })
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  get(id, callback) {
    if (typeof id != "object") {
      if (callback) {
        if (!this.objects[id]) {
          console.log(id);
          axios
            .get(`${this.url}${id}`)
            .then((resp) => {
              this.objects[id] = resp.data;
              callback(resp.data);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          callback(this.objects[id]);
        }
      } else {
        let objects = this.objects;
        if (!objects[id]) {
          return new Promise((resolve, reject) => {
            axios
              .get(`${this.url}${id}`)
              .then((resp) => {
                objects[id] = resp.data;
                resolve(resp.data);
              })
              .catch((error) => {
                reject(error);
              });
          });
        } else {
          return new Promise((resolve, reject) => {
            resolve(objects[id]);
          });
        }
      }
    }
    if (typeof id == "object") {
      if (callback) {
        callback(id);
      } else {
        return new Promise((resolve, reject) => {
          resolve(id);
        });
      }
    }
  }

  add(data, callback) {
    if (shared.activeWorkspace.id) {
      Object.assign(data, { workspace: shared.activeWorkspace.id });
    }
    if (callback) {
      axios
        .post(`${this.url}`, data)
        .then((resp) => {
          callback(resp.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.url}`, data)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  update(id, data, callback) {
    delete this.objects[id];
    if (callback) {
      axios
        .patch(`${this.url}${id}/`, data)
        .then((resp) => {
          callback(resp.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${this.url}${id}/`, data)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  remove(id, callback) {
    if (callback) {
      axios
        .delete(`${this.url}${id}/`)
        .then((resp) => {
          callback(resp.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${this.url}${id}/`)
          .then((resp) => {
            resolve(resp.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  invalidate(id) {
    delete this.objects[id];
  }
  prefetch(ids) {
    ids = Array.from(new Set(ids));
    let promises = ids.map((i) => i ? this.get(i) : new Promise(resolve => resolve()));
    return Promise.all(promises);
  }
}
