<template>
  <div class="px-5">
    <h4
      class="d-flex justify-content-between align-items-center w-100 font-weight-bold my-3"
    >
      <div>Licenças</div>
      <b-btn
        variant="primary btn-round"
        class="d-block"
        v-if="logged_user.account_type == 0"
        v-b-modal.modals-default
      >
        <span class="ion ion-md-add"></span>&nbsp; Adicionar Licença
      </b-btn>
    </h4>

    <b-card>
      <!-- Table -->
      <hr class="border-light m-0" />
      <div class="table-responsive">
        <b-table
          :items="licensesData"
          :fields="fields"
          :striped="true"
          :bordered="true"
          class="card-table"
          ref="table"
          :no-local-sorting="true"
          :key="table_key"
        >
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width ? field.width : '' }"
            />
          </template>
          <template v-slot:cell(id)="data"
            >{{ data.item.id | reduce(8) }}...</template
          >

          <template v-slot:cell(name)="data">
            <div v-if="data.item.editing">
              <input type="text" v-model="data.item.name" />
            </div>
            <div v-else>{{ data.value }}</div>
          </template>

          <template v-slot:cell(valid)="data">
            <i v-if="data.item.valid" class="d-block fas fa-check-circle"></i>
            <i v-else class="d-block fas fa-times-circle"></i>
          </template>

          <template v-slot:cell(actions)="data">
            <b-btn-group>
              <b-btn
                @click="deleteLicense(data.item)"
                variant="default btn-xs icon-btn md-btn-flat"
                v-b-tooltip.hover
                title="Remover Usuário"
              >
                <i class="ion ion-md-trash"></i>
              </b-btn>
            </b-btn-group>
          </template>
        </b-table>
      </div>
    </b-card>
    <b-modal id="modals-default">
      <div slot="modal-title">
        Nova licença
        <br />
        <small class="text-muted">Digite a nova licença para adicionar.</small>
      </div>

      <b-form-row>
        <b-form-group label="Licença" class="col">
          <b-input placeholder="XXXX-XXXX-XXXX-XXXX" v-model="new_license" />
        </b-form-group>
      </b-form-row>
      <footer slot="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          @click="$bvModal.hide('modals-default')"
        >
          Cancela
        </button>
        <button type="button" class="btn btn-primary" @click="add_license">
          Confirma
        </button>
      </footer>
    </b-modal>
  </div>
</template>
<script>
import swal from "sweetalert2";
import loading from "@/utils/loading";
import * as _ from "lodash";

import license_service from "@/services/general/license";
import auth_service from "@/services/auth/auth";
import utils from "@/utils/utils";

import shared from "@/utils/shared";
export default {
  name: "PWorkspaceList",
  metaInfo: {
    title: "Licenças",
  },
  data: () => ({
    logged_user: auth_service.getUserInformation(),
    // Options
    shared,

    fields: [
      {
        key: "code",
        label: "Licença",
        tdClass: "align-middle",
      },
      {
        key: "module",
        label: "Módulo",
        tdClass: "align-middle",
      },
      {
        key: "valid",
        label: "Ativa",
        tdClass: "align-middle",
      },
      {
        key: "valid_until",
        label: "Expira em",
        tdClass: "align-middle",
        formatter(value) {
          let date_obj = new Date(Number(value) * 1000);
          let [day, month, year] = [
            String(date_obj.getDate()).length <= 1
              ? `0${date_obj.getDate()}`
              : `${date_obj.getDate()}`,
            String(date_obj.getMonth() + 1).length <= 1
              ? `0${date_obj.getMonth() + 1}`
              : `${date_obj.getMonth() + 1}`,
            date_obj.getFullYear(),
          ];
          let date = `${day}/${month}/${year}`;
          return date;
        },
      },

      {
        key: "actions",
        label: "Ações",
        tdClass: "text-nowrap align-middle text-right",
        width: "10px",
      },
    ],

    licensesData: [],
    currentPage: 1,
    search_term: "",
    table_key: utils.uuidv4(),
    new_license: "",
  }),

  computed: {},
  methods: {
    refresh() {
      let license_service_loading = loading.addLoader("license_service");

      license_service.all().then((resp) => {
        this.licensesData = resp;

        license_service_loading.done();
      });

      loading.isDone();
    },
    date: (d) =>
      d ? (d._d ? utils.to_locale_date(d._d) : utils.to_locale_date(d)) : "",
    deleteLicense(item) {
      if (item.core) {
        swal.fire(
          "Não permitido",
          "Não é possivel deletar a licença principal",
          "error"
        );
      } else {
        swal
          .fire({
            title: "Tem certeza?",
            text: "Tem certeza que deseja remover essa licença?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          })
          .then((result) => {
            if (result.value) {
              license_service.remove(item.id).then(() => {
                this.refresh();
              });
            }
          });
      }
    },
    add_license(event) {
      let license_validating = loading.addLoader("license_validating");
      let copy = { code: this.new_license };
      license_service
        .add(copy)
        .then((resp) => {
          swal
            .fire({
              title: "Sucesso",
              text: "Licença adicionada com sucesso",
              type: "success",
            })
            .then(() => {
              this.refresh();
            });
        })
        .catch((error) => {
          swal.fire({
            title: "Falha",
            text: "Não foi possivel validar este código de licença",
            type: "error",
          });
        })
        .finally(() => {
          this.new_license = "";
          this.$bvModal.hide("modals-default");
          license_validating.done();
        });
    },
  },
  created() {
    this.deboucedRefreshTable = _.debounce(() => {
      this.$refs.table && this.$refs.table.refresh();
      this.$set(this, "table_key", utils.uuidv4());
    }, 50);
    this.refresh();
  },
};
</script>
